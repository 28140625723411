// =============== Common ===============
.typography-paragraph() {
  margin-bottom: 1em;
}

.typography-title(@fontSize; @fontWeight; @lineHeight; @headingColor; @headingMarginBottom;) {
  margin-bottom: @headingMarginBottom;
  color: @headingColor;
  font-weight: @fontWeight;
  font-size: @fontSize;
  line-height: @lineHeight;
}

.typography-title-1() {
  .typography-title(
    @heading-1-size,
    @typography-title-font-weight,
    1.23,
    @heading-color,
    @typography-title-margin-bottom
  );
}
.typography-title-2() {
  .typography-title(
    @heading-2-size,
    @typography-title-font-weight,
    1.35,
    @heading-color,
    @typography-title-margin-bottom
  );
}
.typography-title-3() {
  .typography-title(
    @heading-3-size,
    @typography-title-font-weight,
    1.35,
    @heading-color,
    @typography-title-margin-bottom
  );
}
.typography-title-4() {
  .typography-title(
    @heading-4-size,
    @typography-title-font-weight,
    1.4,
    @heading-color,
    @typography-title-margin-bottom
  );
}
.typography-title-5() {
  .typography-title(
    @heading-5-size,
    @typography-title-font-weight,
    1.5,
    @heading-color,
    @typography-title-margin-bottom
  );
}
.typography-title-6() {
  .typography-title(
    @heading-6-size,
    @typography-title-font-weight,
    1.5,
    @heading-color,
    @typography-title-margin-bottom
  );
}