@import "~@vnpt/oneui-ui/oneui-ui.less";
@import '~@ctrl/ngx-emoji-mart/picker';
// The prefix to use on all css classes from ant.
@oneui-prefix: oneui;

// An override for the html selector for theme prefixes
@html-selector: html;

// ICONFONT
@iconfont-css-prefix: ms-Icon;

* {
    margin: 0;
    padding: 0;
}

a,
button,
input[type=button],
input[type=submit] {
    cursor: pointer;
}

textarea,
input[type=text],
input[type=password] {
    cursor: text;
}
// Common styles
@import "./css/common/colors.less";
@import "./css/common/form.less";
@import "./css/common/size.less";
@import "./css/common/table.less";
@import "./css/common/text.less";
@import "./css/common/gomeet-icons.css";

// Import theme
// @import "./css/theme/light.less";
@import "./css/theme/dark.less";

@import "./css/common/custom.less";

.rtl {
    .oneui-notification-notice-message {
        direction: rtl;
        text-align: end;
    }

}

.ltr {
    .oneui-notification-notice-message {
        direction: ltr;
    }

}

.oneui-notification-notice-message {
    font-size: 14px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 24px !important;
}


.oneui-notification-notice-description {
    font-size: 12px !important;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
    margin-left: 24px !important;
}
.oneui-notification-notice {
    padding: 8px 12px;
}
.oneui-notification-notice-icon {
    font-size: 16px;
}
.oneui-notification-notice-close {
    top: 8px !important;
    right: 12px !important;
}
ag-virtual-scroll > .items-container {
    height: auto !important;
}

a {
    text-decoration: underline !important;
    color:white
}
.backgroundPopover {
    // background: white;
    .oneui-popover-inner{
        background-color: white;
        color: black;
        .text-color {
            color: black;
        }
        .background-popover{
            background-color: white;
            color: black;
            .list-item {
                &:hover {
                    background-color: #e0e0e0;
                }
            }
        }
    }
    .oneui-popover-arrow{
        border-color: white !important;
    }
}
.card-holder { 
    .oneui-card-head {
        border-bottom: none;
    }
}
.select-button{
    .oneui-select-item-option{
        font-size: 12px !important;
    }
}
.oneui-select-item-option{
    font-size: 12px !important;
}

.pooper-height-max{
    max-height: 85vh;
  }
.whiteGuide {
    .oneui-modal-content {
        .oneui-modal-close {
            color: black;
        }
        .oneui-modal-header{
            background-color: white;
            color: black;
        .oneui-modal-title{
            color: black;
        }
        }
    }
}