@import './index';
@import './mixin';

@input-affix-margin: 4px;

.@{oneui-prefix}-input {
  &-affix-wrapper {
    .input();
    display: inline-flex;

    &:hover {
      .hover();
      z-index: 1;

      .@{oneui-prefix}-input-search-with-button & {
        z-index: 0;
      }
    }

    &-focused,
    &:focus {
      z-index: 1;
    }

    &-disabled {
      .@{oneui-prefix}-input[disabled] {
        background: transparent;
      }
    }

    >input.@{oneui-prefix}-input {
      padding: 0;
      border: none;
      outline: none;

      &:focus {
        box-shadow: none;
      }
    }

    &::before {
      width: 0;
      visibility: hidden;
      content: '\a0';
    }
  }

  &-prefix,
  &-suffix {
    display: flex;
    flex: none;
    align-items: center;

    .@{iconfont-css-prefix} {
      font-size: @breadcrumb-icon-font-size - 2px;
      margin: 0 4px;
      color: #8A8886;
      vertical-align: -1px;
      cursor: pointer;
      transition: color 0.3s;
    }
  }

  &-prefix {
    margin-right: @input-affix-margin;
  }

  &-suffix {
    margin-left: @input-affix-margin;
  }
}