@import '../mixins/motion';
@import 'motion/fade';
@import 'motion/move';
@import 'motion/other';
@import 'motion/slide';
@import 'motion/zoom';

// For common/openAnimation
.oneui-motion-collapse-legacy {
  overflow: hidden;
  &-active {
    transition: height @animation-duration-base @ease-in-out,
      opacity @animation-duration-base @ease-in-out !important;
  }
}

.oneui-motion-collapse {
  overflow: hidden;
  transition: height @animation-duration-base @ease-in-out,
    opacity @animation-duration-base @ease-in-out !important;
}
