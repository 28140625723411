@import '../../style/themes/index';
@import '../../style/mixins/index';
@logo-prefix: ~'@{oneui-prefix}-vnpt-logo';

.@{logo-prefix}-svg{
  display: inline-block!important;
}
.@{logo-prefix}-vSize{
  &-xs{
    font-size: @logo-xs;
    position: relative;
    .@{logo-prefix}-title {
      font-size: 10px;
      line-height: 18px;
      padding-left: 5px;
      margin-left: 3px;
      vertical-align: text-top;
    }
    .@{logo-prefix}-divider {
      height: 12px;
      top: 3px;
      margin-left: 2px;
      width: 1px;
       display: inline-block;
      position: absolute;
      opacity: 0.6;
    }
  }
  &-sm{
    font-size: @logo-sm;
    position: relative;
    .@{logo-prefix}-title {
      font-size: 12px;
      line-height: 22px;
      padding-left: 5px;
      margin-left: 3px;
      vertical-align: text-top;

    }
    .@{logo-prefix}-divider {
      height: 14px;
      top: 4px;
      width: 1px;
       display: inline-block;
      position: absolute;
      margin-left: 2px;
      opacity: 0.6;
    }
  }
  &-md{
    font-size: @logo-md;
    position: relative;
    .@{logo-prefix}-title {
      font-size: 16px;
      line-height: 30px;
      padding-left: 5px;
      margin-left: 3px;
      vertical-align: text-top;

    }
    .@{logo-prefix}-divider {
      height: 18px;
      top: 7px;
      margin-left: 2px;
      width: 1px;
      display: inline-block;
      position: absolute;
      opacity: 0.6;
    }
  }
  &-lg{
    font-size: @logo-lg;
    position: relative;
   .@{logo-prefix}-title {
     font-size: 20px;
     line-height: 34px;
     padding-left: 10px;
     margin-left: 5px;
     vertical-align: text-top;
   }
   .@{logo-prefix}-divider {
     height: 20px;
     top: 8px;
     margin-left: 4px;

     width: 1px;
     display: inline-block;
     position: absolute;
     opacity: 0.6;
   }
  }
  &-xl {
    font-size: @logo-xl;
    position: relative;
    .@{logo-prefix}-title {
      font-size: 22px;
      line-height: 38px;
      padding-left: 10px;
      margin-left: 5px;
      vertical-align: text-top;


    }
    .@{logo-prefix}-divider {
      height: 24px;
      top: 8px;
      margin-left: 4px;
      width: 1px;
      display: inline-block;
      position: absolute;
      opacity: 0.6;

    }
  }
  &-xxl{
    font-size: @logo-xxl;
    position: relative;
    .@{logo-prefix}-title {
      font-size: 24px;
      line-height: 42px;
      padding-left: 10px;
      margin-left: 5px;
      vertical-align: text-top;
    }
    .@{logo-prefix}-divider {
      height: 23px;
      margin-left: 4px;
      top:10px;
      width: 1px;
      display: inline-block;
      position: absolute;
      opacity: 0.6;
    }

  }
  &-liteMode{

    .@{logo-prefix}-svg-text{
      display: none;
    }
  }
  &-customSvg{
    .@{logo-prefix}-svg{
      display: inline-block;
      width: 50px;
    }
  }
  &-img-Logo-sm{
    height: 20px;
    vertical-align: middle;
    object-fit: cover;
  }
  &-img-Logo-md{
    height: 28px;
    vertical-align: middle;
    object-fit: cover;
  }
  &-img-Logo-lg{
    height: 32px;
    vertical-align: middle;
    object-fit: cover;
  }

  &-img-Logo-xl{
    height: 36px;
    vertical-align: middle;
    object-fit: cover;
  }
  &-img-Logo-xxl{
    height: 40px;
    vertical-align: middle;
    object-fit: cover;
  }

}
//vXs
 @media screen and(max-width: @screen-sm-min) {
 .@{logo-prefix}-vXs {
  &-xs{
    font-size: @logo-xs;
    position: relative;
    .@{logo-prefix}-title {
      font-size: 10px;
      line-height: 18px;
      padding-left: 5px;
      margin-left: 3px;
      vertical-align: text-top;
    }
    .@{logo-prefix}-divider {
      height: 12px;
      top: 3px;
      margin-left: 2px;
      width: 1px;
      display: inline-block;
      position: absolute;
      opacity: 0.6;
    }
  }
  &-sm{
    font-size: @logo-sm;
    position: relative;
    .@{logo-prefix}-title {
      font-size: 12px;
      line-height: 22px;
      padding-left: 5px;
      margin-left: 3px;
      vertical-align: text-top;

    }
    .@{logo-prefix}-divider {
      height: 14px;
      top: 4px;
      width: 1px;
      display: inline-block;
      position: absolute;
      margin-left: 2px;
      opacity: 0.6;
    }
  }
  &-md{
    font-size: @logo-md;
    position: relative;
    .@{logo-prefix}-title {
      font-size: 16px;
      line-height: 30px;
      padding-left: 5px;
      margin-left: 3px;
      vertical-align: text-top;

    }
    .@{logo-prefix}-divider {
      height: 18px;
      top: 7px;
      margin-left: 2px;
      width: 1px;
      display: inline-block;
      position: absolute;
      opacity: 0.6;
    }
  }
  &-lg{
    font-size: @logo-lg;
    position: relative;
   .@{logo-prefix}-title {
     font-size: 20px;
     line-height: 34px;
     padding-left: 10px;
     margin-left: 5px;
     vertical-align: text-top;
   }
   .@{logo-prefix}-divider {
     height: 20px;
     top: 8px;
     margin-left: 4px;

     width: 1px;
     display: inline-block;
     position: absolute;
     opacity: 0.6;
   }
  }
  &-xl {
    font-size: @logo-xl;
    position: relative;
    .@{logo-prefix}-title {
      font-size: 22px;
      line-height: 38px;
      padding-left: 10px;
      margin-left: 5px;
      vertical-align: text-top;


    }
    .@{logo-prefix}-divider {
      height: 24px;
      top: 8px;
      margin-left: 4px;
      width: 1px;
      display: inline-block;
      position: absolute;
      opacity: 0.6;

    }
  }
  &-xxl{
    font-size: @logo-xxl;
    position: relative;
    .@{logo-prefix}-title {
      font-size: 24px;
      line-height: 42px;
      padding-left: 10px;
      margin-left: 5px;
      vertical-align: text-top;
    }
    .@{logo-prefix}-divider {
      height: 23px;
      margin-left: 4px;
      top:10px;
      width: 1px;
      display: inline-block;
      position: absolute;
      opacity: 0.6;
    }
  }
  &-liteMode{
    .@{logo-prefix}-svg-text{
      display: none;
    }
  }
  &-customSvg{
    .@{logo-prefix}-svg{
      display: inline-block;
      width: 25px;
    }
  }
  &-img-Logo-xs{
    height: 16px !important;
    vertical-align: middle;
    object-fit: cover;
  }
  &-img-Logo-sm{
    height: 20px;
    vertical-align: middle;
    object-fit: cover;
  }
  &-img-Logo-md{
    height: 28px;
    vertical-align: middle;
    object-fit: cover;
  }
  &-img-Logo-lg{
    height: 32px;
    vertical-align: middle;
    object-fit: cover;
  }

  &-img-Logo-xl{
    height: 36px;
    vertical-align: middle;
    object-fit: cover;
  }
  &-img-Logo-xxl{
    height: 40px;
    vertical-align: middle;
    object-fit: cover;
  }



 }

}
//vSm
@media (min-width : @screen-sm-min ) and (max-width : @screen-md-min ) {
  .@{logo-prefix}-vSm {
    &-xs{
      font-size: @logo-xs;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 10px;
        line-height: 18px;
        padding-left: 5px;
        margin-left: 3px;
        vertical-align: text-top;
      }
      .@{logo-prefix}-divider {
        height: 12px;
        top: 3px;
        margin-left: 2px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;
      }
    }
    &-sm{
      font-size: @logo-sm;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 12px;
        line-height: 22px;
        padding-left: 5px;
        margin-left: 3px;
        vertical-align: text-top;

      }
      .@{logo-prefix}-divider {
        height: 14px;
        top: 4px;
        width: 1px;
        display: inline-block;
        position: absolute;
        margin-left: 2px;
        opacity: 0.6;
      }
    }
    &-md{
      font-size: @logo-md;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 16px;
        line-height: 30px;
        padding-left: 5px;
        margin-left: 3px;
        vertical-align: text-top;

      }
      .@{logo-prefix}-divider {
        height: 18px;
        top: 7px;
        margin-left: 2px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;
      }
    }
    &-lg{
      font-size: @logo-lg;
      position: relative;
     .@{logo-prefix}-title {
       font-size: 20px;
       line-height: 34px;
       padding-left: 10px;
       margin-left: 5px;
       vertical-align: text-top;
     }
     .@{logo-prefix}-divider {
       height: 20px;
       top: 8px;
       margin-left: 4px;

       width: 1px;
       display: inline-block;
       position: absolute;
       opacity: 0.6;
     }
    }
    &-xl {
      font-size: @logo-xl;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 22px;
        line-height: 38px;
        padding-left: 10px;
        margin-left: 5px;
        vertical-align: text-top;


      }
      .@{logo-prefix}-divider {
        height: 24px;
        top: 8px;
        margin-left: 4px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;

      }
    }
    &-xxl{
      font-size: @logo-xxl;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 24px;
        line-height: 42px;
        padding-left: 10px;
        margin-left: 5px;
        vertical-align: text-top;
      }
      .@{logo-prefix}-divider {
        height: 23px;
        margin-left: 4px;
        top:10px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;
      }
    }
    &-liteMode{
      .@{logo-prefix}-svg-text{
        display: none;
      }
    }
    &-customSvg{
      .@{logo-prefix}-svg{
        display: inline-block;
        width: 30px;
      }
    }
    &-img-Logo-xs{
      height: 16px !important;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-sm{
      height: 20px;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-md{
      height: 28px;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-lg{
      height: 32px;
      vertical-align: middle;
      object-fit: cover;
    }

    &-img-Logo-xl{
      height: 36px;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-xxl{
      height: 40px;
      vertical-align: middle;
      object-fit: cover;
    }


}
}
//vMd
@media (min-width : @screen-md-min ) and (max-width : @screen-lg-min ) {
  .@{logo-prefix}-vMd {
    &-xs{
      font-size: @logo-xs;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 10px;
        line-height: 18px;
        padding-left: 5px;
        margin-left: 3px;
        vertical-align: text-top;
      }
      .@{logo-prefix}-divider {
        height: 12px;
        top: 3px;
        margin-left: 2px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;
      }
    }
    &-sm{
      font-size: @logo-sm;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 12px;
        line-height: 22px;
        padding-left: 5px;
        margin-left: 3px;
        vertical-align: text-top;

      }
      .@{logo-prefix}-divider {
        height: 14px;
        top: 4px;
        width: 1px;
        display: inline-block;
        position: absolute;
        margin-left: 2px;
        opacity: 0.6;
      }
    }
    &-md{
      font-size: @logo-md;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 16px;
        line-height: 30px;
        padding-left: 5px;
        margin-left: 3px;
        vertical-align: text-top;

      }
      .@{logo-prefix}-divider {
        height: 18px;
        top: 7px;
        margin-left: 2px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;
      }
    }
    &-lg{
      font-size: @logo-lg;
      position: relative;
     .@{logo-prefix}-title {
       font-size: 20px;
       line-height: 34px;
       padding-left: 10px;
       margin-left: 5px;
       vertical-align: text-top;
     }
     .@{logo-prefix}-divider {
       height: 20px;
       top: 8px;
       margin-left: 4px;

       width: 1px;
       display: inline-block;
       position: absolute;
       opacity: 0.6;
     }
    }
    &-xl {
      font-size: @logo-xl;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 22px;
        line-height: 38px;
        padding-left: 10px;
        margin-left: 5px;
        vertical-align: text-top;


      }
      .@{logo-prefix}-divider {
        height: 24px;
        top: 8px;
        margin-left: 4px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;

      }
    }
    &-xxl{
      font-size: @logo-xxl;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 24px;
        line-height: 42px;
        padding-left: 10px;
        margin-left: 5px;
        vertical-align: text-top;
      }
      .@{logo-prefix}-divider {
        height: 23px;
        margin-left: 4px;
        top:10px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;
      }
    }
    &-liteMode{
      .@{logo-prefix}-svg-text{
        display: none;
      }
    }
    &-customSvg{
      .@{logo-prefix}-svg{
        display: inline-block;
        width: 45px;
      }
    }
    &-img-Logo-xs{
      height: 16px !important;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-sm{
      height: 20px;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-md{
      height: 28px;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-lg{
      height: 32px;
      vertical-align: middle;
      object-fit: cover;
    }

    &-img-Logo-xl{
      height: 36px;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-xxl{
      height: 40px;
      vertical-align: middle;
      object-fit: cover;
    }


}
}
//vLg
@media (min-width : @screen-lg-min ) and (max-width : @screen-xl-min ) {
  .@{logo-prefix}-vLg {
    &-xs{
      font-size: @logo-xs;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 10px;
        line-height: 18px;
        padding-left: 5px;
        margin-left: 3px;
        vertical-align: text-top;
      }
      .@{logo-prefix}-divider {
        height: 12px;
        top: 3px;
        margin-left: 2px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;
      }
    }
    &-sm{
      font-size: @logo-sm;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 12px;
        line-height: 22px;
        padding-left: 5px;
        margin-left: 3px;
        vertical-align: text-top;

      }
      .@{logo-prefix}-divider {
        height: 14px;
        top: 4px;
        width: 1px;
        display: inline-block;
        position: absolute;
        margin-left: 2px;
        opacity: 0.6;
      }
    }
    &-md{
      font-size: @logo-md;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 16px;
        line-height: 30px;
        padding-left: 5px;
        margin-left: 3px;
        vertical-align: text-top;

      }
      .@{logo-prefix}-divider {
        height: 18px;
        top: 7px;
        margin-left: 2px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;
      }
    }
    &-lg{
      font-size: @logo-lg;
      position: relative;
     .@{logo-prefix}-title {
       font-size: 20px;
       line-height: 34px;
       padding-left: 10px;
       margin-left: 5px;
       vertical-align: text-top;
     }
     .@{logo-prefix}-divider {
       height: 20px;
       top: 8px;
       margin-left: 4px;

       width: 1px;
       display: inline-block;
       position: absolute;
       opacity: 0.6;
     }
    }
    &-xl {
      font-size: @logo-xl;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 22px;
        line-height: 38px;
        padding-left: 10px;
        margin-left: 5px;
        vertical-align: text-top;


      }
      .@{logo-prefix}-divider {
        height: 24px;
        top: 8px;
        margin-left: 4px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;

      }
    }
    &-xxl{
      font-size: @logo-xxl;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 24px;
        line-height: 42px;
        padding-left: 10px;
        margin-left: 5px;
        vertical-align: text-top;
      }
      .@{logo-prefix}-divider {
        height: 23px;
        margin-left: 4px;
        top:10px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;
      }
    }
    &-liteMode{

      .@{logo-prefix}-svg-text{
        display: none;
      }
    }
    &-customSvg{
      .@{logo-prefix}-svg{
        display: inline-block;
        width: 45px;
      }
    }
    &-img-Logo-xs{
      height: 16px !important;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-sm{
      height: 47px !important;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-md{
      height: 50px !important;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-lg{
      height: 55px !important;
      vertical-align: middle;
      object-fit: cover;
    }

    &-img-Logo-xl{
      height: 60px !important;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-xxl{
      height: 65px;
      vertical-align: middle;
      object-fit: cover;
    }


}
}
//vXl
@media (min-width : @screen-xl-min ) and (max-width : @screen-xxl-min ) {
  .@{logo-prefix}-vXl {
    &-xs{
      font-size: @logo-xs;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 10px;
        line-height: 18px;
        padding-left: 5px;
        margin-left: 3px;
        vertical-align: text-top;
      }
      .@{logo-prefix}-divider {
        height: 12px;
        top: 3px;
        margin-left: 2px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;
      }
    }
    &-sm{
      font-size: @logo-sm;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 12px;
        line-height: 22px;
        padding-left: 5px;
        margin-left: 3px;
        vertical-align: text-top;

      }
      .@{logo-prefix}-divider {
        height: 14px;
        top: 4px;
        width: 1px;
        display: inline-block;
        position: absolute;
        margin-left: 2px;
        opacity: 0.6;
      }
    }
    &-md{
      font-size: @logo-md;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 16px;
        line-height: 30px;
        padding-left: 5px;
        margin-left: 3px;
        vertical-align: text-top;

      }
      .@{logo-prefix}-divider {
        height: 18px;
        top: 7px;
        margin-left: 2px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;
      }
    }
    &-lg{
      font-size: @logo-lg;
      position: relative;
     .@{logo-prefix}-title {
       font-size: 20px;
       line-height: 34px;
       padding-left: 10px;
       margin-left: 5px;
       vertical-align: text-top;
     }
     .@{logo-prefix}-divider {
       height: 20px;
       top: 8px;
       margin-left: 4px;

       width: 1px;
       display: inline-block;
       position: absolute;
       opacity: 0.6;
     }
    }
    &-xl {
      font-size: @logo-xl;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 22px;
        line-height: 38px;
        padding-left: 10px;
        margin-left: 5px;
        vertical-align: text-top;


      }
      .@{logo-prefix}-divider {
        height: 24px;
        top: 8px;
        margin-left: 4px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;

      }
    }
    &-xxl{
      font-size: @logo-xxl;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 24px;
        line-height: 42px;
        padding-left: 10px;
        margin-left: 5px;
        vertical-align: text-top;
      }
      .@{logo-prefix}-divider {
        height: 23px;
        margin-left: 4px;
        top:10px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;
      }
    }
    &-liteMode{

      .@{logo-prefix}-svg-text{
        display: none;
      }
    }
    &-customSvg{
      .@{logo-prefix}-svg{
        display: inline-block;
        width: 45px;
      }
    }
    &-img-Logo-sm{
      height: 20px;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-md{
      height: 28px;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-lg{
      height: 32px;
      vertical-align: middle;
      object-fit: cover;
    }

    &-img-Logo-xl{
      height: 36px;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-xxl{
      height: 40px;
      vertical-align: middle;
      object-fit: cover;
    }


}
}
//vXxl
@media (min-width: @screen-xxl-min) {
  .@{logo-prefix}-vXxl {
    &-xs{
      font-size: @logo-xs;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 10px;
        line-height: 18px;
        padding-left: 5px;
        margin-left: 3px;
        vertical-align: text-top;
      }
      .@{logo-prefix}-divider {
        height: 12px;
        top: 3px;
        margin-left: 2px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;
      }
    }
    &-sm{
      font-size: @logo-sm;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 12px;
        line-height: 22px;
        padding-left: 5px;
        margin-left: 3px;
        vertical-align: text-top;

      }
      .@{logo-prefix}-divider {
        height: 14px;
        top: 4px;
        width: 1px;
        display: inline-block;
        position: absolute;
        margin-left: 2px;
        opacity: 0.6;
      }
    }
    &-md{
      font-size: @logo-md;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 16px;
        line-height: 30px;
        padding-left: 5px;
        margin-left: 3px;
        vertical-align: text-top;

      }
      .@{logo-prefix}-divider {
        height: 18px;
        top: 7px;
        margin-left: 2px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;
      }
    }
    &-lg{
      font-size: @logo-lg;
      position: relative;
     .@{logo-prefix}-title {
       font-size: 20px;
       line-height: 34px;
       padding-left: 10px;
       margin-left: 5px;
       vertical-align: text-top;
     }
     .@{logo-prefix}-divider {
       height: 20px;
       top: 8px;
       margin-left: 4px;

       width: 1px;
       display: inline-block;
       position: absolute;
       opacity: 0.6;
     }
    }
    &-xl {
      font-size: @logo-xl;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 22px;
        line-height: 38px;
        padding-left: 10px;
        margin-left: 5px;
        vertical-align: text-top;


      }
      .@{logo-prefix}-divider {
        height: 24px;
        top: 8px;
        margin-left: 4px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;

      }
    }
    &-xxl{
      font-size: @logo-xxl;
      position: relative;
      .@{logo-prefix}-title {
        font-size: 24px;
        line-height: 42px;
        padding-left: 10px;
        margin-left: 5px;
        vertical-align: text-top;
      }
      .@{logo-prefix}-divider {
        height: 23px;
        margin-left: 4px;
        top:10px;
        width: 1px;
        display: inline-block;
        position: absolute;
        opacity: 0.6;
      }
    }
    &-liteMode{

      .@{logo-prefix}-svg-text{
        display: none;
      }
    }
    &-customSvg{
      .@{logo-prefix}-svg{
        display: inline-block;
        width: 50px;
      }
    }
    &-img-Logo-xs{
      height: 16px !important;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-sm{
      height: 20px;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-md{
      height: 28px;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-lg{
      height: 32px;
      vertical-align: middle;
      object-fit: cover;
    }

    &-img-Logo-xl{
      height: 36px;
      vertical-align: middle;
      object-fit: cover;
    }
    &-img-Logo-xxl{
      height: 40px;
      vertical-align: middle;
      object-fit: cover;
    }

}

}
/* .img-Logo-xs{
  height: 45px;
  vertical-align: middle;
  object-fit: cover;
}
.img-Logo-sm{
  height: 47px;
  vertical-align: middle;
  object-fit: cover;
}
.img-Logo-md{
  height: 50px;
  vertical-align: middle;
  object-fit: cover;
}
.img-Logo-lg{
  height: 55px;
  vertical-align: middle;
  object-fit: cover;
}

.img-Logo-xl{
  height: 60px;
  vertical-align: middle;
  object-fit: cover;
}
.img-Logo-xxl{
  height: 65px;
  vertical-align: middle;
  object-fit: cover;
} */

@import './rtl';