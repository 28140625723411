/* stylelint-disable at-rule-no-unknown */

// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css

// HTML & Body reset
@{html-selector},
body {
  .square(100%);
}

// remove the clear button of a text input control in IE10+
input::-ms-clear,
input::-ms-reveal {
  display: none;
}

// Document
//
// 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
// 2. Change the default font family in all browsers.
// 3. Correct the line height in all browsers.
// 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 5. Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so
//    we force a non-overlapping, non-auto-hiding scrollbar to counteract.
// 6. Change the default tap highlight to be completely transparent in iOS.

*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

@{html-selector} {
  font-family: sans-serif; // 2
  line-height: 1.15; // 3
  -webkit-text-size-adjust: 100%; // 4
  -ms-text-size-adjust: 100%; // 4
  -ms-overflow-style: scrollbar; // 5
  -webkit-tap-highlight-color: fade(@black, 0%); // 6
}

// IE10+ doesn't honor `<meta name="viewport">` in some cases.
@-ms-viewport {
  width: device-width;
}

// Body
//
// 1. remove the margin in all browsers.
// 2. As a best practice, apply a default `body-background`.

body {
  margin: 0; // 1
  color: @text-color;
  font-size: @font-size-base;
  font-family: @font-family;
  font-variant: @font-varioneui-base;
  line-height: @line-height-base;
  background-color: @body-background; // 2
  font-feature-settings: @font-feature-settings-base;
}

// Suppress the focus outline on elements that cannot be accessed via keyboard.
// This prevents an unwanted focus outline from appearing around elements that
// might still respond to pointer events.
//
// Credit: https://github.com/suitcss/base
[tabindex='-1']:focus {
  outline: none !important;
}

// Content grouping
//
// 1. Add the correct box sizing in Firefox.
// 2. Show the overflow in Edge and IE.

hr {
  box-sizing: content-box; // 1
  height: 0; // 1
  overflow: visible; // 2
}

//
// Typography
//

// remove top margins from headings
//
// By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
// margin for easier control within type scales as it avoids margin collapsing.
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: @heading-color;
  font-weight: 600;
}

// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `em` units instead of `em`.
p {
  margin-top: 0;
  margin-bottom: 1em;
}

// Abbreviations
//
// 1. remove the bottom border in Firefox 39-.
// 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
// 3. Add explicit cursor to indicate changed behavior.
// 4. Duplicate behavior to the data-* attribute for our tooltip plugin

abbr[title],
abbr[data-original-title] {
  // 4
  text-decoration: underline; // 2
  text-decoration: underline dotted; // 2
  border-bottom: 0; // 1
  cursor: help; // 3
}

address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5em;
  margin-left: 0; // Undo browser default
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic; // Add the correct font style in Android 4.3-
}

b,
strong {
  font-weight: bolder; // Add the correct font weight in Chrome, Edge, and Safari
}

small {
  font-size: 80%; // Add the correct font size in all browsers
}

//
// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.
//

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}

//
// Links
//

a {
  color: @link-color;
  text-decoration: @link-decoration;
  background-color: transparent; // remove the gray background on active links in IE 10.
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects; // remove gaps in links underline in iOS 8+ and Safari 8+.

  &:hover {
    color: @link-hover-color;
  }

  &:active {
    color: @link-active-color;
  }

  &:active,
  &:hover {
    text-decoration: @link-hover-decoration;
    outline: 0;
  }

  // https://github.com/oneui-design/oneui-design/issues/22503
  &:focus {
    text-decoration: @link-focus-decoration;
    outline: @link-focus-outline;
  }

  &[disabled] {
    color: @disabled-color;
    cursor: not-allowed;
    pointer-events: none;
  }
}

//
// Code
//

pre,
code,
kbd,
samp {
  font-size: 1em; // Correct the odd `em` font sizing in all browsers.
  font-family: @code-family;
}

pre {
  // remove browser default top margin
  margin-top: 0;
  // Reset browser default of `1em` to use `em`s
  margin-bottom: 1em;
  // Don't allow content to break outside
  overflow: auto;
}

//
// Figures
//
figure {
  // Apply a consistent margin strategy (matches our type styles).
  margin: 0 0 1em;
}

//
// Images and content
//

img {
  vertical-align: middle;
  border-style: none; // remove the border on images inside links in IE 10-.
}

svg:not(:root) {
  overflow: hidden; // Hide the overflow in IE
}

// Avoid 300ms click delay on touch devices that support the `touch-action` CSS property.
//
// In particular, unlike most other browsers, IE11+Edge on Windows 10 on touch devices and IE Mobile 10-11
// DON'T remove the click delay when `<meta name="viewport" content="width=device-width">` is present.
// However, they DO support emoving the click delay via `touch-action: manipulation`.
// See:
// * https://getbootstrap.com/docs/4.0/content/reboot/#click-delay-optimization-for-touch
// * http://caniuse.com/#feat=css-touch-action
// * https://patrickhlauke.github.io/touch/tests/results/#suppressing-300ms-delay

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

//
// Tables
//

table {
  border-collapse: collapse; // Prevent double borders
}

caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: @text-color-secondary;
  text-align: left;
  caption-side: bottom;
}

th {
  // Matches default `<td>` alignment by inheriting from the `<body>`, or the
  // closest parent with a set `text-align`.
  text-align: inherit;
}

//
// Forms
//

input,
button,
select,
optgroup,
textarea {
  margin: 0; // remove the margin in Firefox and Safari
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible; // Show the overflow in Edge
}

button,
select {
  text-transform: none; // remove the inheritance of text transform in Firefox
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
button,
@{html-selector} [type="button"], /* 1 */
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; // 2
}

// remove inner border and padding from Firefox, but don't restore the outline like Normalize.
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box; // 1. Add the correct box sizing in IE 10-
  padding: 0; // 2. remove the padding in IE 10-
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  // remove the default appearance of temporal inputs to avoid a Mobile Safari
  // bug where setting a custom line-height prevents text from being vertically
  // centered within the input.
  // See https://bugs.webkit.org/show_bug.cgi?id=139848
  // and https://github.com/twbs/bootstrap/issues/11266
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto; // remove the default vertical scrollbar in IE.
  // Textareas should really only resize vertically so they don't break their (horizontal) containers.
  resize: vertical;
}

fieldset {
  // Browsers set a default `min-width: min-content;` on fieldsets,
  // unlike e.g. `<div>`s, which have `min-width: 0;` by default.
  // So we reset that to ensure fieldsets behave more like a standard block element.
  // See https://github.com/twbs/bootstrap/issues/12359
  // and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
  min-width: 0;
  margin: 0;
  // Reset the default outline behavior of fieldsets so they don't affect page layout.
  padding: 0;
  border: 0;
}

// 1. Correct the text wrapping in Edge and IE.
// 2. Correct the color inheritance from `fieldset` elements in IE.
legend {
  display: block;
  width: 100%;
  max-width: 100%; // 1
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit; // 2
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal; // 1
}

progress {
  vertical-align: baseline; // Add the correct vertical alignment in Chrome, Firefox, and Opera.
}

// Correct the cursor style of incement and decement buttons in Chrome.
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  // This overrides the extra rounded corners on search inputs in iOS so that our
  // `.form-control` class can properly style them. Note that this cannot simply
  // be added to `.form-control` as it's not specific enough. For details, see
  // https://github.com/twbs/bootstrap/issues/11586.
  outline-offset: -2px; // 2. Correct the outline style in Safari.
  -webkit-appearance: none;
}

//
// remove the inner padding and cancel buttons in Chrome and Safari on macOS.
//

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

//
// 1. Correct the inability to style clickable types in iOS and Safari.
// 2. Change font properties to `inherit` in Safari.
//

::-webkit-file-upload-button {
  font: inherit; // 2
  -webkit-appearance: button; // 1
}

//
// Correct element displays
//

output {
  display: inline-block;
}

summary {
  display: list-item; // Add the correct display in all browsers
}

template {
  display: none; // Add the correct display in IE
}

// Always hide an element with the `hidden` HTML attribute (from PureCSS).
// Needed for proper display in IE 10-.
[hidden] {
  display: none !important;
}

mark {
  padding: 0.2em;
  background-color: @yellow-1;
}

::selection {
  color: @text-color-inverse;
  background: @text-selection-bg;
}

// Utility classes
.clearfix {
  .clearfix();
}


.d-none {
  display: none !important;
}


.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: @screen-sm-min) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}


@media (min-width: @screen-md-min) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}


@media (min-width: @screen-lg-min) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}


@media (min-width: @screen-xl-min) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}


@media (min-width: @screen-xxl-min) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.bg {
  &-primary{background-color: @blue-base !important;}
  &-success{background-color: @green-base !important;}
  &-warning{background-color: @gold-6 !important;}
  &-danger{background-color: @red-base !important;}

  &-magenta{ background-color: @magenta-base !important;}
  &-red{background-color: @red-base !important;}
  &-volcano{background-color: @volcano-base !important;}
  &-orange{background-color: @orange-base !important;}
  &-gold{background-color: @gold-base !important;}
  &-lime{background-color: @lime-base !important;}
  &-green{background-color: @green-base !important;}
  &-cyan{background-color: @cyan-base !important;}
  &-blue{background-color: @blue-base !important;}
  &-geekblue{background-color: @geekblue-base !important;}
  &-purple{background-color: @purple-base !important;}
  &-black{background-color: @black !important;}
  &-white{background-color: @white !important;}
  &-transparent{background-color: transparent !important;}
 }

 .text{

   &-primary{color: @blue-base !important;}
   &-success{color: @green-base !important;}
   &-warning{color: @gold-6 !important;}
   &-danger{color: @red-base !important;}

   &-magenta{ color: @magenta-base !important;}
   &-red{color: @red-base !important;}
   &-volcano{color: @volcano-base !important;}
   &-orange{color: @orange-base !important;}
   &-gold{color: @gold-base !important;}
   &-lime{color: @lime-base !important;}
   &-green{color: @green-base !important;}
   &-cyan{color: @cyan-base !important;}
   &-blue{color: @blue-base !important;}
   &-geekblue{color: @geekblue-base !important;}
   &-purple{color: @purple-base !important;}
   &-black{color: @black !important;}
   &-white{color: @white !important;}
 }

 .border{
   &-0{ border-width: 0px !important;}
   &-1{ border-width: 1px !important;}
   &-2{ border-width: 2px !important;}
   &-3{ border-width: 3px !important;}
   &-4{ border-width: 4px !important;}
   &-5{ border-width: 5px !important;}
   &-6{ border-width: 6px !important;}
   &-7{ border-width: 7px !important;}
   &-8{ border-width: 8px !important;}
   &-9{ border-width: 9px !important;;}
   &-10{ border-width: 10px !important;}
   &-11{ border-width: 11px !important;}
 }

 .border-color{
  &-primary{border-color: @blue-base !important;}
  &-success{border-color: @green-base !important;}
  &-warning{border-color: @gold-6 !important;}
  &-danger{border-color: @red-base !important;}
  &-transparent{border-color: transparent !important;}

   &-magenta{ border-color: @magenta-base !important;}
   &-red{border-color: @red-base !important;}
   &-volcano{border-color: @volcano-base !important;}
   &-orange{border-color: @orange-base !important;}
   &-gold{border-color: @gold-base !important;}
   &-lime{border-color: @lime-base !important;}
   &-green{border-color: @green-base !important;}
   &-cyan{border-color: @cyan-base !important;}
   &-blue{border-color: @blue-base !important;}
   &-geekblue{border-color: @geekblue-base !important;}
   &-purple{border-color: @purple-base !important;}
   &-black{border-color: @black !important;}
   &-white{border-color: @white !important;}
 }

 .border-radius{
   &-0{ border-radius: 0 !important;}
   &-1{ border-radius: 0.2rem !important;}
   &-2{ border-radius: 0.25rem !important;}
   &-3{ border-radius: 0.3rem !important;}
   &-4{ border-radius: 0.35rem !important;}
   &-5{ border-radius: 0.4rem !important;}
   &-6{ border-radius: 0.45rem !important;}
   &-7{ border-radius: 0.5rem !important;}
   &-8{ border-radius: 0.55rem !important;}
   &-9{ border-radius: 0.6rem !important;}
   &-10{ border-radius: 0.65rem !important;}
   &-11{ border-radius: 0.7rem !important;}
   &-pill{  border-radius: 50rem !important;}
   &-circle{ border-radius: 50% !important;}
 }

 .valign{
  &-baseline {
    vertical-align: baseline !important;
  }

  &-top {
    vertical-align: top !important;
  }

  &-middle {
    vertical-align: middle !important;
  }

  &-bottom {
    vertical-align: bottom !important;
  }

  &-text-bottom {
    vertical-align: text-bottom !important;
  }

  &-text-top {
    vertical-align: text-top !important;
  }
}

