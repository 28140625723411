@import '../../style/themes/index';
@import '../../style/mixins/index';

@space-prefix-cls: ~'@{oneui-prefix}-space';

.@{space-prefix-cls} {
  &-rtl {
    direction: rtl;
  }
}
