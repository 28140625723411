.oneui-badge {
  .oneui-scroll-number:only-child {
    position: relative;
    top: auto;
    display: block;
  }

  .oneui-badge-count:only-child {
    transform: none;
  }
}

vnpt-ribbon {
  display: block;
}