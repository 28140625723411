@import '../../style/themes/index';
@import '../../style/mixins/index';
@import '../../button/style/mixin';
@import './mixin';

@search-prefix: ~'@{oneui-prefix}-input-search';

.@{search-prefix} {
  .@{oneui-prefix}-input {
    &:hover,
    &:focus {
      border-color: @input-hover-border-color;

      + .@{oneui-prefix}-input-group-addon .@{search-prefix}-button:not(.@{oneui-prefix}-btn-primary) {
        border-left-color: @input-hover-border-color;
      }
    }
  }

  .@{oneui-prefix}-input-affix-wrapper {
    border-radius: 0;
  }

  // fix slight height diff in Firefox:
  // https://ant.design/components/auto-complete-cn/#components-auto-complete-demo-certain-category
  .@{oneui-prefix}-input-lg {
    line-height: @line-height-base - 0.0002;
  }

  > .@{oneui-prefix}-input-group {
    > .@{oneui-prefix}-input-group-addon:last-child {
      left: -1px;
      padding: 0;
      border: 0;

      .@{search-prefix}-button {
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 0 @border-radius-base @border-radius-base 0;
      }

      .@{search-prefix}-button:not(.@{oneui-prefix}-btn-primary) {
        color: @text-color-secondary;

        &.@{oneui-prefix}-btn-loading::before {
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  &-button {
    height: @input-height-base;

    &:hover,
    &:focus {
      z-index: 1;
    }
  }

  &-large &-button {
    height: @input-height-lg;
  }

  &-small &-button {
    height: @input-height-sm;
  }
}
