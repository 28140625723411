@import '../../style/themes/index';
@import '../../style/mixins/index';

@popover-prefix-cls: ~'@{oneui-prefix}-popover';

.@{popover-prefix-cls} {
  &-rtl {
    direction: rtl;
    text-align: right;
  }

  &-message {
    &-title {
      .@{popover-prefix-cls}-rtl & {
        padding-right: @font-size-base + 8px;
        padding-left: @padding-md;
      }
    }
  }

  &-buttons {
    .@{popover-prefix-cls}-rtl & {
      text-align: left;
    }

    button {
      .@{popover-prefix-cls}-rtl & {
        margin-right: 8px;
        margin-left: 0;
      }
    }
  }
}
