@import '../../style/themes/index';
@import '../../style/mixins/index';

@pageheader-prefix-cls: ~'@{oneui-prefix}-page-header';

.@{pageheader-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-back {
    .@{pageheader-prefix-cls}-rtl & {
      float: right;
      margin-right: 0;
      margin-left: 16px;
    }
  }

  &-heading {
    &-title {
      .@{pageheader-prefix-cls}-rtl & {
        margin-right: 0;
        margin-left: @margin-sm;
      }
    }

    .@{oneui-prefix}-avatar {
      .@{pageheader-prefix-cls}-rtl & {
        margin-right: 0;
        margin-left: @margin-sm;
      }
    }

    &-sub-title {
      .@{pageheader-prefix-cls}-rtl & {
        float: right;
        margin-right: 0;
        margin-left: 12px;
      }
    }

    &-tags {
      .@{pageheader-prefix-cls}-rtl & {
        float: right;
      }
    }

    &-extra {
      .@{pageheader-prefix-cls}-rtl & {
        float: left;
      }

      > * {
        .@{pageheader-prefix-cls}-rtl & {
          margin-right: @margin-sm;
          margin-left: 0;
        }
      }
      > *:first-child {
        .@{pageheader-prefix-cls}-rtl & {
          margin-right: 0;
        }
      }
    }
  }

  &-footer {
    .@{oneui-prefix}-tabs-bar {
      .@{oneui-prefix}-tabs-nav {
        .@{pageheader-prefix-cls}-rtl & {
          float: right;
        }
      }
    }
  }
}
