@import "~@vnpt/oneui-ui/oneui-ui.dark.less";
@import "../common/common.less";

// Input placeholder
::-webkit-input-placeholder { /* Edge */
    color: @white !important;
    opacity: 0.6;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: @white !important;
    opacity: 0.6;
}

::placeholder {
    color: @white !important;
    opacity: 0.6;
}

// Scrollbar 
::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    opacity: 0.6;
    border-radius: 4px;
}

// Override dark theme
@primary-color: #286BB0;
@success-color: #29C83F;
@error-color: #D9534F;
@warning-color: #FF9601;
@info-color: @primary-color;


@body-background: #0E1621;
@component-background: #1F3B55;
// @border-color-base: #0E1621;
// @border-color-split: #0E1621;

// Checkbox
@checkbox-check-color: @text-color;
@checkbox-check-bg: @text-color;

// Divider
@divider-color: #303030;

// Input
.oneui-input:hover, .oneui-input:focus {
    border-color: @text-color;
}

// Popover
@popover-padding-horizontal: 0px;

// Radio
@radio-dot-color: @text-color;

// Switch
@switch-color: @component-background;

// Select
@select-border-color: @text-color;
@select-item-active-bg: @gray-10;
@select-item-selected-bg: @gray-10;
@select-item-selected-color: @text-color;

// Alert
@alert-success-border-color: @green-4;
@alert-success-bg-color: @green-4;
@alert-success-icon-color: @success-color;
@alert-info-border-color: @primary-4;
@alert-info-bg-color: @primary-4;
@alert-info-icon-color: @info-color;
@alert-warning-border-color: @gold-4;
@alert-warning-bg-color: @gold-4;
@alert-warning-icon-color: @warning-color;
@alert-error-border-color: @red-4;
@alert-error-bg-color: @red-4;
@alert-error-icon-color: @error-color;

// Spin
.oneui-spin {
    color: @text-color !important;
}

.oneui-spin-dot-item {
    background-color: @text-color !important;
}

// Result
.oneui-result {
    padding: 0 32px;
}

.oneui-result-info .oneui-result-icon > .ms-Icon {
    color: @text-color;
}