@import '../../style/themes/index';
@import '../../style/mixins/index';

@collapse-prefix-cls: ~'@{oneui-prefix}-collapse';

.@{collapse-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  & > &-item {
    > .@{collapse-prefix-cls}-header {
      .@{collapse-prefix-cls}-rtl & {
        padding: @collapse-header-padding;
        padding-right: @collapse-header-padding-extra;
      }

      .@{collapse-prefix-cls}-arrow {
          .@{collapse-prefix-cls}-rtl& {
            transform: rotate(180deg);
          }
      }

      .@{collapse-prefix-cls}-extra {
        .@{collapse-prefix-cls}-rtl& {
          float: left;
        }
      }
    }

    &.@{collapse-prefix-cls}-no-arrow {
      > .@{collapse-prefix-cls}-header {
        .@{collapse-prefix-cls}-rtl& {
          padding-right: 12px;
          padding-left: 0;
        }
      }
    }
  }
}
