@progress-prefix-cls: ~'@{oneui-prefix}-progress';

.@{steps-prefix-cls}-with-progress {
  .@{steps-prefix-cls}-item {
    padding-top: 4px;

    .@{steps-prefix-cls}-item-tail {
      top: 4px !important;
    }
  }

  .@{steps-prefix-cls}-item-icon {
    position: relative;

    .@{progress-prefix-cls} {
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
    }
  }
}
