@import './index.less';

@card-prefix-cls: ~'@{oneui-prefix}-card';

.@{list-prefix-cls} {
  // =================== Dard Hook Components ===================
  .@{card-prefix-cls} {
    & when (@theme = dark) {
      background: @list-customize-card-bg;
    }
  }
}
