.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: @padding-1 !important;
}

.p-2 {
  padding: @padding-2 !important;
}

.p-3 {
  padding: @padding-3 !important;
}

.p-4 {
  padding: @padding-4 !important;
}

.p-5 {
  padding: @padding-5 !important;
}
.p-6 {
  padding: @padding-6 !important;
}

.p-7 {
  padding: @padding-7 !important;
}

.p-8 {
  padding: @padding-8 !important;
}

.p-9 {
  padding: @padding-9 !important;
}

.p-10 {
  padding: @padding-10 !important;
}

.p-11 {
  padding: @padding-11 !important;
}


//pading left
.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: @padding-1 !important;
}

.pl-2 {
  padding-left: @padding-2 !important;
}

.pl-3 {
  padding-left: @padding-3 !important;
}

.pl-4 {
  padding-left: @padding-4 !important;
}

.pl-5 {
  padding-left: @padding-5 !important;
}
.pl-6 {
  padding-left: @padding-6 !important;
}

.pl-7 {
  padding-left: @padding-7 !important;
}

.pl-8 {
  padding-left: @padding-8 !important;
}

.pl-9 {
  padding-left: @padding-9 !important;
}

.pl-10 {
  padding-left: @padding-10 !important;
}

.pl-11 {
  padding-left: @padding-11 !important;
}


//padding right
.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: @padding-1 !important;
}

.pr-2 {
  padding-right: @padding-2 !important;
}

.pr-3 {
  padding-right: @padding-3 !important;
}

.pr-4 {
  padding-right: @padding-4 !important;
}

.pr-5 {
  padding-right: @padding-5 !important;
}
.pr-6 {
  padding-right: @padding-6 !important;
}

.pr-7 {
  padding-right: @padding-7 !important;
}

.pr-8 {
  padding-right: @padding-8 !important;
}

.pr-9 {
  padding-right: @padding-9 !important;
}

.pr-10 {
  padding-right: @padding-10 !important;
}

.pr-11 {
  padding-right: @padding-11 !important;
}



//padding top
.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: @padding-1 !important;
}

.pt-2 {
  padding-top: @padding-2 !important;
}

.pt-3 {
  padding-top: @padding-3 !important;
}

.pt-4 {
  padding-top: @padding-4 !important;
}

.pt-5 {
  padding-top: @padding-5 !important;
}
.pt-6 {
  padding-top: @padding-6 !important;
}

.pt-7 {
  padding-top: @padding-7 !important;
}

.pt-8 {
  padding-top: @padding-8 !important;
}

.pt-9 {
  padding-top: @padding-9 !important;
}

.pt-10 {
  padding-top: @padding-10 !important;
}

.pt-11 {
  padding-top: @padding-11 !important;
}


//padding bottom
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: @padding-1 !important;
}

.pb-2 {
  padding-bottom: @padding-2 !important;
}

.pb-3 {
  padding-bottom: @padding-3 !important;
}

.pb-4 {
  padding-bottom: @padding-4 !important;
}

.pb-5 {
  padding-bottom: @padding-5 !important;
}
.pb-6 {
  padding-bottom: @padding-6 !important;
}

.pb-7 {
  padding-bottom: @padding-7 !important;
}

.pb-8 {
  padding-bottom: @padding-8 !important;
}

.pb-9 {
  padding-bottom: @padding-9 !important;
}

.pb-10 {
  padding-bottom: @padding-10 !important;
}

.pb-11 {
  padding-bottom: @padding-11 !important;
}

//padding y
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: @padding-1 !important;
  padding-bottom: @padding-1 !important;
}

.py-2 {
  padding-top: @padding-2 !important;
  padding-bottom: @padding-2 !important;
}

.py-3 {
  padding-top: @padding-3 !important;
  padding-bottom: @padding-3 !important;
}

.py-4 {
  padding-top: @padding-4 !important;
  padding-bottom: @padding-4 !important;
}

.py-5 {
  padding-top: @padding-5 !important;
  padding-bottom: @padding-5 !important;
}
.py-6 {
  padding-top: @padding-6 !important;
  padding-bottom: @padding-6 !important;
}

.py-7 {
  padding-top: @padding-7 !important;
  padding-bottom: @padding-7 !important;
}

.py-8 {
  padding-top: @padding-8 !important;
  padding-bottom: @padding-8 !important;
}

.py-9 {
  padding-top: @padding-9 !important;
  padding-bottom: @padding-9 !important;
}

.py-10 {
  padding-top: @padding-10 !important;
  padding-bottom: @padding-10 !important;
}

.py-11 {
  padding-top: @padding-11 !important;
  padding-bottom: @padding-11 !important;
}

//padding x
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: @padding-1 !important;
  padding-right: @padding-1 !important;
}

.px-2 {
  padding-left: @padding-2 !important;
  padding-right: @padding-2 !important;
}

.px-3 {
  padding-left: @padding-3 !important;
  padding-right: @padding-3 !important;
}

.px-4 {
  padding-left: @padding-4 !important;
  padding-right: @padding-4 !important;
}

.px-5 {
  padding-left: @padding-5 !important;
  padding-right: @padding-5 !important;
}
.px-6 {
  padding-left: @padding-6 !important;
  padding-right: @padding-6 !important;
}

.px-7 {
  padding-left: @padding-7 !important;
  padding-right: @padding-7 !important;
}

.px-8 {
  padding-left: @padding-8 !important;
  padding-right: @padding-8 !important;
}

.px-9 {
  padding-left: @padding-9 !important;
  padding-right: @padding-9 !important;
}

.px-10 {
  padding-left: @padding-10 !important;
  padding-right: @padding-10 !important;
}

.px-11 {
  padding-left: @padding-11 !important;
  padding-right: @padding-11 !important;
}


@media (min-width: @screen-sm-min) {
  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: @padding-1 !important;
  }

  .p-sm-2 {
    padding: @padding-2 !important;
  }

  .p-sm-3 {
    padding: @padding-3 !important;
  }

  .p-sm-4 {
    padding: @padding-4 !important;
  }

  .p-sm-5 {
    padding: @padding-5 !important;
  }
  .p-sm-6 {
    padding: @padding-6 !important;
  }

  .p-sm-7 {
    padding: @padding-7 !important;
  }

  .p-sm-8 {
    padding: @padding-8 !important;
  }

  .p-sm-9 {
    padding: @padding-9 !important;
  }

  .p-sm-10 {
    padding: @padding-10 !important;
  }

  .p-sm-11 {
    padding: @padding-11 !important;
  }


  //pading left
  .pl-sm-0 {
    padding-left: 0 !important;
  }

  .pl-sm-1 {
    padding-left: @padding-1 !important;
  }

  .pl-sm-2 {
    padding-left: @padding-2 !important;
  }

  .pl-sm-3 {
    padding-left: @padding-3 !important;
  }

  .pl-sm-4 {
    padding-left: @padding-4 !important;
  }

  .pl-sm-5 {
    padding-left: @padding-5 !important;
  }
  .pl-sm-6 {
    padding-left: @padding-6 !important;
  }

  .pl-sm-7 {
    padding-left: @padding-7 !important;
  }

  .pl-sm-8 {
    padding-left: @padding-8 !important;
  }

  .pl-sm-9 {
    padding-left: @padding-9 !important;
  }

  .pl-sm-10 {
    padding-left: @padding-10 !important;
  }

  .pl-sm-11 {
    padding-left: @padding-11 !important;
  }


  //padding right
  .pr-sm-0 {
    padding-right: 0 !important;
  }

  .pr-sm-1 {
    padding-right: @padding-1 !important;
  }

  .pr-sm-2 {
    padding-right: @padding-2 !important;
  }

  .pr-sm-3 {
    padding-right: @padding-3 !important;
  }

  .pr-sm-4 {
    padding-right: @padding-4 !important;
  }

  .pr-sm-5 {
    padding-right: @padding-5 !important;
  }
  .pr-sm-6 {
    padding-right: @padding-6 !important;
  }

  .pr-sm-7 {
    padding-right: @padding-7 !important;
  }

  .pr-sm-8 {
    padding-right: @padding-8 !important;
  }

  .pr-sm-9 {
    padding-right: @padding-9 !important;
  }

  .pr-sm-10 {
    padding-right: @padding-10 !important;
  }

  .pr-sm-11 {
    padding-right: @padding-11 !important;
  }



  //padding top
  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: @padding-1 !important;
  }

  .pt-sm-2 {
    padding-top: @padding-2 !important;
  }

  .pt-sm-3 {
    padding-top: @padding-3 !important;
  }

  .pt-sm-4 {
    padding-top: @padding-4 !important;
  }

  .pt-sm-5 {
    padding-top: @padding-5 !important;
  }
  .pt-sm-6 {
    padding-top: @padding-6 !important;
  }

  .pt-sm-7 {
    padding-top: @padding-7 !important;
  }

  .pt-sm-8 {
    padding-top: @padding-8 !important;
  }

  .pt-sm-9 {
    padding-top: @padding-9 !important;
  }

  .pt-sm-10 {
    padding-top: @padding-10 !important;
  }

  .pt-sm-11 {
    padding-top: @padding-11 !important;
  }


  //padding bottom
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: @padding-1 !important;
  }

  .pb-sm-2 {
    padding-bottom: @padding-2 !important;
  }

  .pb-sm-3 {
    padding-bottom: @padding-3 !important;
  }

  .pb-sm-4 {
    padding-bottom: @padding-4 !important;
  }

  .pb-sm-5 {
    padding-bottom: @padding-5 !important;
  }
  .pb-sm-6 {
    padding-bottom: @padding-6 !important;
  }

  .pb-sm-7 {
    padding-bottom: @padding-7 !important;
  }

  .pb-sm-8 {
    padding-bottom: @padding-8 !important;
  }

  .pb-sm-9 {
    padding-bottom: @padding-9 !important;
  }

  .pb-sm-10 {
    padding-bottom: @padding-10 !important;
  }

  .pb-sm-11 {
    padding-bottom: @padding-11 !important;
  }

  //padding y
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: @padding-1 !important;
    padding-bottom: @padding-1 !important;
  }

  .py-sm-2 {
    padding-top: @padding-2 !important;
    padding-bottom: @padding-2 !important;
  }

  .py-sm-3 {
    padding-top: @padding-3 !important;
    padding-bottom: @padding-3 !important;
  }

  .py-sm-4 {
    padding-top: @padding-4 !important;
    padding-bottom: @padding-4 !important;
  }

  .py-sm-5 {
    padding-top: @padding-5 !important;
    padding-bottom: @padding-5 !important;
  }
  .py-sm-6 {
    padding-top: @padding-6 !important;
    padding-bottom: @padding-6 !important;
  }

  .py-sm-7 {
    padding-top: @padding-7 !important;
    padding-bottom: @padding-7 !important;
  }

  .py-sm-8 {
    padding-top: @padding-8 !important;
    padding-bottom: @padding-8 !important;
  }

  .py-sm-9 {
    padding-top: @padding-9 !important;
    padding-bottom: @padding-9 !important;
  }

  .py-sm-10 {
    padding-top: @padding-10 !important;
    padding-bottom: @padding-10 !important;
  }

  .py-sm-11 {
    padding-top: @padding-11 !important;
    padding-bottom: @padding-11 !important;
  }

  //padding x
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: @padding-1 !important;
    padding-right: @padding-1 !important;
  }

  .px-sm-2 {
    padding-left: @padding-2 !important;
    padding-right: @padding-2 !important;
  }

  .px-sm-3 {
    padding-left: @padding-3 !important;
    padding-right: @padding-3 !important;
  }

  .px-sm-4 {
    padding-left: @padding-4 !important;
    padding-right: @padding-4 !important;
  }

  .px-sm-5 {
    padding-left: @padding-5 !important;
    padding-right: @padding-5 !important;
  }
  .px-sm-6 {
    padding-left: @padding-6 !important;
    padding-right: @padding-6 !important;
  }

  .px-sm-7 {
    padding-left: @padding-7 !important;
    padding-right: @padding-7 !important;
  }

  .px-sm-8 {
    padding-left: @padding-8 !important;
    padding-right: @padding-8 !important;
  }

  .px-sm-9 {
    padding-left: @padding-9 !important;
    padding-right: @padding-9 !important;
  }

  .px-sm-10 {
    padding-left: @padding-10 !important;
    padding-right: @padding-10 !important;
  }

  .px-sm-11 {
    padding-left: @padding-11 !important;
    padding-right: @padding-11 !important;
  }
}

@media (min-width: @screen-md-min) {
  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: @padding-1 !important;
  }

  .p-md-2 {
    padding: @padding-2 !important;
  }

  .p-md-3 {
    padding: @padding-3 !important;
  }

  .p-md-4 {
    padding: @padding-4 !important;
  }

  .p-md-5 {
    padding: @padding-5 !important;
  }
  .p-md-6 {
    padding: @padding-6 !important;
  }

  .p-md-7 {
    padding: @padding-7 !important;
  }

  .p-md-8 {
    padding: @padding-8 !important;
  }

  .p-md-9 {
    padding: @padding-9 !important;
  }

  .p-md-10 {
    padding: @padding-10 !important;
  }

  .p-md-11 {
    padding: @padding-11 !important;
  }


  //pading left
  .pl-md-0 {
    padding-left: 0 !important;
  }

  .pl-md-1 {
    padding-left: @padding-1 !important;
  }

  .pl-md-2 {
    padding-left: @padding-2 !important;
  }

  .pl-md-3 {
    padding-left: @padding-3 !important;
  }

  .pl-md-4 {
    padding-left: @padding-4 !important;
  }

  .pl-md-5 {
    padding-left: @padding-5 !important;
  }
  .pl-md-6 {
    padding-left: @padding-6 !important;
  }

  .pl-md-7 {
    padding-left: @padding-7 !important;
  }

  .pl-md-8 {
    padding-left: @padding-8 !important;
  }

  .pl-md-9 {
    padding-left: @padding-9 !important;
  }

  .pl-md-10 {
    padding-left: @padding-10 !important;
  }

  .pl-md-11 {
    padding-left: @padding-11 !important;
  }


  //padding right
  .pr-md-0 {
    padding-right: 0 !important;
  }

  .pr-md-1 {
    padding-right: @padding-1 !important;
  }

  .pr-md-2 {
    padding-right: @padding-2 !important;
  }

  .pr-md-3 {
    padding-right: @padding-3 !important;
  }

  .pr-md-4 {
    padding-right: @padding-4 !important;
  }

  .pr-md-5 {
    padding-right: @padding-5 !important;
  }
  .pr-md-6 {
    padding-right: @padding-6 !important;
  }

  .pr-md-7 {
    padding-right: @padding-7 !important;
  }

  .pr-md-8 {
    padding-right: @padding-8 !important;
  }

  .pr-md-9 {
    padding-right: @padding-9 !important;
  }

  .pr-md-10 {
    padding-right: @padding-10 !important;
  }

  .pr-md-11 {
    padding-right: @padding-11 !important;
  }



  //padding top
  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: @padding-1 !important;
  }

  .pt-md-2 {
    padding-top: @padding-2 !important;
  }

  .pt-md-3 {
    padding-top: @padding-3 !important;
  }

  .pt-md-4 {
    padding-top: @padding-4 !important;
  }

  .pt-md-5 {
    padding-top: @padding-5 !important;
  }
  .pt-md-6 {
    padding-top: @padding-6 !important;
  }

  .pt-md-7 {
    padding-top: @padding-7 !important;
  }

  .pt-md-8 {
    padding-top: @padding-8 !important;
  }

  .pt-md-9 {
    padding-top: @padding-9 !important;
  }

  .pt-md-10 {
    padding-top: @padding-10 !important;
  }

  .pt-md-11 {
    padding-top: @padding-11 !important;
  }


  //padding bottom
  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: @padding-1 !important;
  }

  .pb-md-2 {
    padding-bottom: @padding-2 !important;
  }

  .pb-md-3 {
    padding-bottom: @padding-3 !important;
  }

  .pb-md-4 {
    padding-bottom: @padding-4 !important;
  }

  .pb-md-5 {
    padding-bottom: @padding-5 !important;
  }
  .pb-md-6 {
    padding-bottom: @padding-6 !important;
  }

  .pb-md-7 {
    padding-bottom: @padding-7 !important;
  }

  .pb-md-8 {
    padding-bottom: @padding-8 !important;
  }

  .pb-md-9 {
    padding-bottom: @padding-9 !important;
  }

  .pb-md-10 {
    padding-bottom: @padding-10 !important;
  }

  .pb-md-11 {
    padding-bottom: @padding-11 !important;
  }

  //padding y
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: @padding-1 !important;
    padding-bottom: @padding-1 !important;
  }

  .py-md-2 {
    padding-top: @padding-2 !important;
    padding-bottom: @padding-2 !important;
  }

  .py-md-3 {
    padding-top: @padding-3 !important;
    padding-bottom: @padding-3 !important;
  }

  .py-md-4 {
    padding-top: @padding-4 !important;
    padding-bottom: @padding-4 !important;
  }

  .py-md-5 {
    padding-top: @padding-5 !important;
    padding-bottom: @padding-5 !important;
  }
  .py-md-6 {
    padding-top: @padding-6 !important;
    padding-bottom: @padding-6 !important;
  }

  .py-md-7 {
    padding-top: @padding-7 !important;
    padding-bottom: @padding-7 !important;
  }

  .py-md-8 {
    padding-top: @padding-8 !important;
    padding-bottom: @padding-8 !important;
  }

  .py-md-9 {
    padding-top: @padding-9 !important;
    padding-bottom: @padding-9 !important;
  }

  .py-md-10 {
    padding-top: @padding-10 !important;
    padding-bottom: @padding-10 !important;
  }

  .py-md-11 {
    padding-top: @padding-11 !important;
    padding-bottom: @padding-11 !important;
  }

  //padding x
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: @padding-1 !important;
    padding-right: @padding-1 !important;
  }

  .px-md-2 {
    padding-left: @padding-2 !important;
    padding-right: @padding-2 !important;
  }

  .px-md-3 {
    padding-left: @padding-3 !important;
    padding-right: @padding-3 !important;
  }

  .px-md-4 {
    padding-left: @padding-4 !important;
    padding-right: @padding-4 !important;
  }

  .px-md-5 {
    padding-left: @padding-5 !important;
    padding-right: @padding-5 !important;
  }
  .px-md-6 {
    padding-left: @padding-6 !important;
    padding-right: @padding-6 !important;
  }

  .px-md-7 {
    padding-left: @padding-7 !important;
    padding-right: @padding-7 !important;
  }

  .px-md-8 {
    padding-left: @padding-8 !important;
    padding-right: @padding-8 !important;
  }

  .px-md-9 {
    padding-left: @padding-9 !important;
    padding-right: @padding-9 !important;
  }

  .px-md-10 {
    padding-left: @padding-10 !important;
    padding-right: @padding-10 !important;
  }

  .px-md-11 {
    padding-left: @padding-11 !important;
    padding-right: @padding-11 !important;
  }
}



@media (min-width: @screen-lg-min) {
  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: @padding-1 !important;
  }

  .p-lg-2 {
    padding: @padding-2 !important;
  }

  .p-lg-3 {
    padding: @padding-3 !important;
  }

  .p-lg-4 {
    padding: @padding-4 !important;
  }

  .p-lg-5 {
    padding: @padding-5 !important;
  }
  .p-lg-6 {
    padding: @padding-6 !important;
  }

  .p-lg-7 {
    padding: @padding-7 !important;
  }

  .p-lg-8 {
    padding: @padding-8 !important;
  }

  .p-lg-9 {
    padding: @padding-9 !important;
  }

  .p-lg-10 {
    padding: @padding-10 !important;
  }

  .p-lg-11 {
    padding: @padding-11 !important;
  }


  //pading left
  .pl-lg-0 {
    padding-left: 0 !important;
  }

  .pl-lg-1 {
    padding-left: @padding-1 !important;
  }

  .pl-lg-2 {
    padding-left: @padding-2 !important;
  }

  .pl-lg-3 {
    padding-left: @padding-3 !important;
  }

  .pl-lg-4 {
    padding-left: @padding-4 !important;
  }

  .pl-lg-5 {
    padding-left: @padding-5 !important;
  }
  .pl-lg-6 {
    padding-left: @padding-6 !important;
  }

  .pl-lg-7 {
    padding-left: @padding-7 !important;
  }

  .pl-lg-8 {
    padding-left: @padding-8 !important;
  }

  .pl-lg-9 {
    padding-left: @padding-9 !important;
  }

  .pl-lg-10 {
    padding-left: @padding-10 !important;
  }

  .pl-lg-11 {
    padding-left: @padding-11 !important;
  }


  //padding right
  .pr-lg-0 {
    padding-right: 0 !important;
  }

  .pr-lg-1 {
    padding-right: @padding-1 !important;
  }

  .pr-lg-2 {
    padding-right: @padding-2 !important;
  }

  .pr-lg-3 {
    padding-right: @padding-3 !important;
  }

  .pr-lg-4 {
    padding-right: @padding-4 !important;
  }

  .pr-lg-5 {
    padding-right: @padding-5 !important;
  }
  .pr-lg-6 {
    padding-right: @padding-6 !important;
  }

  .pr-lg-7 {
    padding-right: @padding-7 !important;
  }

  .pr-lg-8 {
    padding-right: @padding-8 !important;
  }

  .pr-lg-9 {
    padding-right: @padding-9 !important;
  }

  .pr-lg-10 {
    padding-right: @padding-10 !important;
  }

  .pr-lg-11 {
    padding-right: @padding-11 !important;
  }



  //padding top
  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: @padding-1 !important;
  }

  .pt-lg-2 {
    padding-top: @padding-2 !important;
  }

  .pt-lg-3 {
    padding-top: @padding-3 !important;
  }

  .pt-lg-4 {
    padding-top: @padding-4 !important;
  }

  .pt-lg-5 {
    padding-top: @padding-5 !important;
  }
  .pt-lg-6 {
    padding-top: @padding-6 !important;
  }

  .pt-lg-7 {
    padding-top: @padding-7 !important;
  }

  .pt-lg-8 {
    padding-top: @padding-8 !important;
  }

  .pt-lg-9 {
    padding-top: @padding-9 !important;
  }

  .pt-lg-10 {
    padding-top: @padding-10 !important;
  }

  .pt-lg-11 {
    padding-top: @padding-11 !important;
  }


  //padding bottom
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: @padding-1 !important;
  }

  .pb-lg-2 {
    padding-bottom: @padding-2 !important;
  }

  .pb-lg-3 {
    padding-bottom: @padding-3 !important;
  }

  .pb-lg-4 {
    padding-bottom: @padding-4 !important;
  }

  .pb-lg-5 {
    padding-bottom: @padding-5 !important;
  }
  .pb-lg-6 {
    padding-bottom: @padding-6 !important;
  }

  .pb-lg-7 {
    padding-bottom: @padding-7 !important;
  }

  .pb-lg-8 {
    padding-bottom: @padding-8 !important;
  }

  .pb-lg-9 {
    padding-bottom: @padding-9 !important;
  }

  .pb-lg-10 {
    padding-bottom: @padding-10 !important;
  }

  .pb-lg-11 {
    padding-bottom: @padding-11 !important;
  }

  //padding y
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: @padding-1 !important;
    padding-bottom: @padding-1 !important;
  }

  .py-lg-2 {
    padding-top: @padding-2 !important;
    padding-bottom: @padding-2 !important;
  }

  .py-lg-3 {
    padding-top: @padding-3 !important;
    padding-bottom: @padding-3 !important;
  }

  .py-lg-4 {
    padding-top: @padding-4 !important;
    padding-bottom: @padding-4 !important;
  }

  .py-lg-5 {
    padding-top: @padding-5 !important;
    padding-bottom: @padding-5 !important;
  }
  .py-lg-6 {
    padding-top: @padding-6 !important;
    padding-bottom: @padding-6 !important;
  }

  .py-lg-7 {
    padding-top: @padding-7 !important;
    padding-bottom: @padding-7 !important;
  }

  .py-lg-8 {
    padding-top: @padding-8 !important;
    padding-bottom: @padding-8 !important;
  }

  .py-lg-9 {
    padding-top: @padding-9 !important;
    padding-bottom: @padding-9 !important;
  }

  .py-lg-10 {
    padding-top: @padding-10 !important;
    padding-bottom: @padding-10 !important;
  }

  .py-lg-11 {
    padding-top: @padding-11 !important;
    padding-bottom: @padding-11 !important;
  }

  //padding x
  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: @padding-1 !important;
    padding-right: @padding-1 !important;
  }

  .px-lg-2 {
    padding-left: @padding-2 !important;
    padding-right: @padding-2 !important;
  }

  .px-lg-3 {
    padding-left: @padding-3 !important;
    padding-right: @padding-3 !important;
  }

  .px-lg-4 {
    padding-left: @padding-4 !important;
    padding-right: @padding-4 !important;
  }

  .px-lg-5 {
    padding-left: @padding-5 !important;
    padding-right: @padding-5 !important;
  }
  .px-lg-6 {
    padding-left: @padding-6 !important;
    padding-right: @padding-6 !important;
  }

  .px-lg-7 {
    padding-left: @padding-7 !important;
    padding-right: @padding-7 !important;
  }

  .px-lg-8 {
    padding-left: @padding-8 !important;
    padding-right: @padding-8 !important;
  }

  .px-lg-9 {
    padding-left: @padding-9 !important;
    padding-right: @padding-9 !important;
  }

  .px-lg-10 {
    padding-left: @padding-10 !important;
    padding-right: @padding-10 !important;
  }

  .px-lg-11 {
    padding-left: @padding-11 !important;
    padding-right: @padding-11 !important;
  }
}


@media (min-width: @screen-xl-min) {
  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: @padding-1 !important;
  }

  .p-xl-2 {
    padding: @padding-2 !important;
  }

  .p-xl-3 {
    padding: @padding-3 !important;
  }

  .p-xl-4 {
    padding: @padding-4 !important;
  }

  .p-xl-5 {
    padding: @padding-5 !important;
  }
  .p-xl-6 {
    padding: @padding-6 !important;
  }

  .p-xl-7 {
    padding: @padding-7 !important;
  }

  .p-xl-8 {
    padding: @padding-8 !important;
  }

  .p-xl-9 {
    padding: @padding-9 !important;
  }

  .p-xl-10 {
    padding: @padding-10 !important;
  }

  .p-xl-11 {
    padding: @padding-11 !important;
  }


  //pading left
  .pl-xl-0 {
    padding-left: 0 !important;
  }

  .pl-xl-1 {
    padding-left: @padding-1 !important;
  }

  .pl-xl-2 {
    padding-left: @padding-2 !important;
  }

  .pl-xl-3 {
    padding-left: @padding-3 !important;
  }

  .pl-xl-4 {
    padding-left: @padding-4 !important;
  }

  .pl-xl-5 {
    padding-left: @padding-5 !important;
  }
  .pl-xl-6 {
    padding-left: @padding-6 !important;
  }

  .pl-xl-7 {
    padding-left: @padding-7 !important;
  }

  .pl-xl-8 {
    padding-left: @padding-8 !important;
  }

  .pl-xl-9 {
    padding-left: @padding-9 !important;
  }

  .pl-xl-10 {
    padding-left: @padding-10 !important;
  }

  .pl-xl-11 {
    padding-left: @padding-11 !important;
  }


  //padding right
  .pr-xl-0 {
    padding-right: 0 !important;
  }

  .pr-xl-1 {
    padding-right: @padding-1 !important;
  }

  .pr-xl-2 {
    padding-right: @padding-2 !important;
  }

  .pr-xl-3 {
    padding-right: @padding-3 !important;
  }

  .pr-xl-4 {
    padding-right: @padding-4 !important;
  }

  .pr-xl-5 {
    padding-right: @padding-5 !important;
  }
  .pr-xl-6 {
    padding-right: @padding-6 !important;
  }

  .pr-xl-7 {
    padding-right: @padding-7 !important;
  }

  .pr-xl-8 {
    padding-right: @padding-8 !important;
  }

  .pr-xl-9 {
    padding-right: @padding-9 !important;
  }

  .pr-xl-10 {
    padding-right: @padding-10 !important;
  }

  .pr-xl-11 {
    padding-right: @padding-11 !important;
  }



  //padding top
  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: @padding-1 !important;
  }

  .pt-xl-2 {
    padding-top: @padding-2 !important;
  }

  .pt-xl-3 {
    padding-top: @padding-3 !important;
  }

  .pt-xl-4 {
    padding-top: @padding-4 !important;
  }

  .pt-xl-5 {
    padding-top: @padding-5 !important;
  }
  .pt-xl-6 {
    padding-top: @padding-6 !important;
  }

  .pt-xl-7 {
    padding-top: @padding-7 !important;
  }

  .pt-xl-8 {
    padding-top: @padding-8 !important;
  }

  .pt-xl-9 {
    padding-top: @padding-9 !important;
  }

  .pt-xl-10 {
    padding-top: @padding-10 !important;
  }

  .pt-xl-11 {
    padding-top: @padding-11 !important;
  }


  //padding bottom
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: @padding-1 !important;
  }

  .pb-xl-2 {
    padding-bottom: @padding-2 !important;
  }

  .pb-xl-3 {
    padding-bottom: @padding-3 !important;
  }

  .pb-xl-4 {
    padding-bottom: @padding-4 !important;
  }

  .pb-xl-5 {
    padding-bottom: @padding-5 !important;
  }
  .pb-xl-6 {
    padding-bottom: @padding-6 !important;
  }

  .pb-xl-7 {
    padding-bottom: @padding-7 !important;
  }

  .pb-xl-8 {
    padding-bottom: @padding-8 !important;
  }

  .pb-xl-9 {
    padding-bottom: @padding-9 !important;
  }

  .pb-xl-10 {
    padding-bottom: @padding-10 !important;
  }

  .pb-xl-11 {
    padding-bottom: @padding-11 !important;
  }

  //padding y
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: @padding-1 !important;
    padding-bottom: @padding-1 !important;
  }

  .py-xl-2 {
    padding-top: @padding-2 !important;
    padding-bottom: @padding-2 !important;
  }

  .py-xl-3 {
    padding-top: @padding-3 !important;
    padding-bottom: @padding-3 !important;
  }

  .py-xl-4 {
    padding-top: @padding-4 !important;
    padding-bottom: @padding-4 !important;
  }

  .py-xl-5 {
    padding-top: @padding-5 !important;
    padding-bottom: @padding-5 !important;
  }
  .py-xl-6 {
    padding-top: @padding-6 !important;
    padding-bottom: @padding-6 !important;
  }

  .py-xl-7 {
    padding-top: @padding-7 !important;
    padding-bottom: @padding-7 !important;
  }

  .py-xl-8 {
    padding-top: @padding-8 !important;
    padding-bottom: @padding-8 !important;
  }

  .py-xl-9 {
    padding-top: @padding-9 !important;
    padding-bottom: @padding-9 !important;
  }

  .py-xl-10 {
    padding-top: @padding-10 !important;
    padding-bottom: @padding-10 !important;
  }

  .py-xl-11 {
    padding-top: @padding-11 !important;
    padding-bottom: @padding-11 !important;
  }

  //padding x
  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: @padding-1 !important;
    padding-right: @padding-1 !important;
  }

  .px-xl-2 {
    padding-left: @padding-2 !important;
    padding-right: @padding-2 !important;
  }

  .px-xl-3 {
    padding-left: @padding-3 !important;
    padding-right: @padding-3 !important;
  }

  .px-xl-4 {
    padding-left: @padding-4 !important;
    padding-right: @padding-4 !important;
  }

  .px-xl-5 {
    padding-left: @padding-5 !important;
    padding-right: @padding-5 !important;
  }
  .px-xl-6 {
    padding-left: @padding-6 !important;
    padding-right: @padding-6 !important;
  }

  .px-xl-7 {
    padding-left: @padding-7 !important;
    padding-right: @padding-7 !important;
  }

  .px-xl-8 {
    padding-left: @padding-8 !important;
    padding-right: @padding-8 !important;
  }

  .px-xl-9 {
    padding-left: @padding-9 !important;
    padding-right: @padding-9 !important;
  }

  .px-xl-10 {
    padding-left: @padding-10 !important;
    padding-right: @padding-10 !important;
  }

  .px-xl-11 {
    padding-left: @padding-11 !important;
    padding-right: @padding-11 !important;
  }
}


@media (min-width: @screen-xxl-min) {
  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: @padding-1 !important;
  }

  .p-xxl-2 {
    padding: @padding-2 !important;
  }

  .p-xxl-3 {
    padding: @padding-3 !important;
  }

  .p-xxl-4 {
    padding: @padding-4 !important;
  }

  .p-xxl-5 {
    padding: @padding-5 !important;
  }
  .p-xxl-6 {
    padding: @padding-6 !important;
  }

  .p-xxl-7 {
    padding: @padding-7 !important;
  }

  .p-xxl-8 {
    padding: @padding-8 !important;
  }

  .p-xxl-9 {
    padding: @padding-9 !important;
  }

  .p-xxl-10 {
    padding: @padding-10 !important;
  }

  .p-xxl-11 {
    padding: @padding-11 !important;
  }


  //pading left
  .pl-xxl-0 {
    padding-left: 0 !important;
  }

  .pl-xxl-1 {
    padding-left: @padding-1 !important;
  }

  .pl-xxl-2 {
    padding-left: @padding-2 !important;
  }

  .pl-xxl-3 {
    padding-left: @padding-3 !important;
  }

  .pl-xxl-4 {
    padding-left: @padding-4 !important;
  }

  .pl-xxl-5 {
    padding-left: @padding-5 !important;
  }
  .pl-xxl-6 {
    padding-left: @padding-6 !important;
  }

  .pl-xxl-7 {
    padding-left: @padding-7 !important;
  }

  .pl-xxl-8 {
    padding-left: @padding-8 !important;
  }

  .pl-xxl-9 {
    padding-left: @padding-9 !important;
  }

  .pl-xxl-10 {
    padding-left: @padding-10 !important;
  }

  .pl-xxl-11 {
    padding-left: @padding-11 !important;
  }


  //padding right
  .pr-xxl-0 {
    padding-right: 0 !important;
  }

  .pr-xxl-1 {
    padding-right: @padding-1 !important;
  }

  .pr-xxl-2 {
    padding-right: @padding-2 !important;
  }

  .pr-xxl-3 {
    padding-right: @padding-3 !important;
  }

  .pr-xxl-4 {
    padding-right: @padding-4 !important;
  }

  .pr-xxl-5 {
    padding-right: @padding-5 !important;
  }
  .pr-xxl-6 {
    padding-right: @padding-6 !important;
  }

  .pr-xxl-7 {
    padding-right: @padding-7 !important;
  }

  .pr-xxl-8 {
    padding-right: @padding-8 !important;
  }

  .pr-xxl-9 {
    padding-right: @padding-9 !important;
  }

  .pr-xxl-10 {
    padding-right: @padding-10 !important;
  }

  .pr-xxl-11 {
    padding-right: @padding-11 !important;
  }



  //padding top
  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: @padding-1 !important;
  }

  .pt-xxl-2 {
    padding-top: @padding-2 !important;
  }

  .pt-xxl-3 {
    padding-top: @padding-3 !important;
  }

  .pt-xxl-4 {
    padding-top: @padding-4 !important;
  }

  .pt-xxl-5 {
    padding-top: @padding-5 !important;
  }
  .pt-xxl-6 {
    padding-top: @padding-6 !important;
  }

  .pt-xxl-7 {
    padding-top: @padding-7 !important;
  }

  .pt-xxl-8 {
    padding-top: @padding-8 !important;
  }

  .pt-xxl-9 {
    padding-top: @padding-9 !important;
  }

  .pt-xxl-10 {
    padding-top: @padding-10 !important;
  }

  .pt-xxl-11 {
    padding-top: @padding-11 !important;
  }


  //padding bottom
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: @padding-1 !important;
  }

  .pb-xxl-2 {
    padding-bottom: @padding-2 !important;
  }

  .pb-xxl-3 {
    padding-bottom: @padding-3 !important;
  }

  .pb-xxl-4 {
    padding-bottom: @padding-4 !important;
  }

  .pb-xxl-5 {
    padding-bottom: @padding-5 !important;
  }
  .pb-xxl-6 {
    padding-bottom: @padding-6 !important;
  }

  .pb-xxl-7 {
    padding-bottom: @padding-7 !important;
  }

  .pb-xxl-8 {
    padding-bottom: @padding-8 !important;
  }

  .pb-xxl-9 {
    padding-bottom: @padding-9 !important;
  }

  .pb-xxl-10 {
    padding-bottom: @padding-10 !important;
  }

  .pb-xxl-11 {
    padding-bottom: @padding-11 !important;
  }

  //padding y
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: @padding-1 !important;
    padding-bottom: @padding-1 !important;
  }

  .py-xxl-2 {
    padding-top: @padding-2 !important;
    padding-bottom: @padding-2 !important;
  }

  .py-xxl-3 {
    padding-top: @padding-3 !important;
    padding-bottom: @padding-3 !important;
  }

  .py-xxl-4 {
    padding-top: @padding-4 !important;
    padding-bottom: @padding-4 !important;
  }

  .py-xxl-5 {
    padding-top: @padding-5 !important;
    padding-bottom: @padding-5 !important;
  }
  .py-xxl-6 {
    padding-top: @padding-6 !important;
    padding-bottom: @padding-6 !important;
  }

  .py-xxl-7 {
    padding-top: @padding-7 !important;
    padding-bottom: @padding-7 !important;
  }

  .py-xxl-8 {
    padding-top: @padding-8 !important;
    padding-bottom: @padding-8 !important;
  }

  .py-xxl-9 {
    padding-top: @padding-9 !important;
    padding-bottom: @padding-9 !important;
  }

  .py-xxl-10 {
    padding-top: @padding-10 !important;
    padding-bottom: @padding-10 !important;
  }

  .py-xxl-11 {
    padding-top: @padding-11 !important;
    padding-bottom: @padding-11 !important;
  }

  //padding x
  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: @padding-1 !important;
    padding-right: @padding-1 !important;
  }

  .px-xxl-2 {
    padding-left: @padding-2 !important;
    padding-right: @padding-2 !important;
  }

  .px-xxl-3 {
    padding-left: @padding-3 !important;
    padding-right: @padding-3 !important;
  }

  .px-xxl-4 {
    padding-left: @padding-4 !important;
    padding-right: @padding-4 !important;
  }

  .px-xxl-5 {
    padding-left: @padding-5 !important;
    padding-right: @padding-5 !important;
  }
  .px-xxl-6 {
    padding-left: @padding-6 !important;
    padding-right: @padding-6 !important;
  }

  .px-xxl-7 {
    padding-left: @padding-7 !important;
    padding-right: @padding-7 !important;
  }

  .px-xxl-8 {
    padding-left: @padding-8 !important;
    padding-right: @padding-8 !important;
  }

  .px-xxl-9 {
    padding-left: @padding-9 !important;
    padding-right: @padding-9 !important;
  }

  .px-xxl-10 {
    padding-left: @padding-10 !important;
    padding-right: @padding-10 !important;
  }

  .px-xxl-11 {
    padding-left: @padding-11 !important;
    padding-right: @padding-11 !important;
  }
}


