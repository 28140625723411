.oneui-tree.oneui-select-tree.oneui-tree-show-line vnpt-tree-node[builtin]:not(:last-child) > li::before {
  content: ' ';
  width: 1px;
  border-left: 1px solid #d9d9d9;
  height: calc(100% - 16px);
  position: absolute;
  left: 12px;
  margin: 26px 0;
}

.oneui-select-dropdown.oneui-select-tree-dropdown {
  top: 100%;
  left: 0;
  position: relative;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  overflow: auto;
}