.@{avatar-prefix-cls}-group {
  display: inline-flex;

  .@{avatar-prefix-cls} {
    border: 1px solid @avatar-group-border-color;

    &:not(:first-child) {
      margin-left: @avatar-group-overlapping;
    }
  }

  &-popover {
    .@{oneui-prefix}-avatar + .@{oneui-prefix}-avatar {
      margin-left: @avatar-group-space;
    }
  }
}
