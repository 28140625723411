vnpt-content {
  display: block;
}

vnpt-footer {
  display: block;
}

vnpt-header {
  display: block;
}
