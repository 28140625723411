@import '../../style/themes/index';
@import '../../style/mixins/index';

@timeline-prefix-cls: ~'@{oneui-prefix}-timeline';

.@{timeline-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-item {
    &-tail {
      .@{timeline-prefix-cls}-rtl & {
        right: 4px;
        left: auto;
        border-right: @timeline-width solid @timeline-color;
        border-left: none;
      }
    }

    &-head-custom {
      .@{timeline-prefix-cls}-rtl & {
        right: 5px;
        left: auto;
        transform: translate(50%, -50%);
      }
    }

    &-content {
      .@{timeline-prefix-cls}-rtl & {
        margin: 0 18px 0 0;
      }
    }
  }

  &.@{timeline-prefix-cls}-alternate,
  &.@{timeline-prefix-cls}-right,
  &.@{timeline-prefix-cls}-label {
    .@{timeline-prefix-cls}-item {
      &-tail,
      &-head,
      &-head-custom {
        .@{timeline-prefix-cls}-rtl& {
          right: 50%;
          left: auto;
        }
      }

      &-head {
        .@{timeline-prefix-cls}-rtl& {
          margin-right: -4px;
          margin-left: 0;
        }

        &-custom {
          .@{timeline-prefix-cls}-rtl& {
            margin-right: 1px;
            margin-left: 0;
          }
        }
      }

      &-left {
        .@{timeline-prefix-cls}-item-content {
          .@{timeline-prefix-cls}-rtl& {
            right: calc(50% - 4px);
            left: auto;
            text-align: right;
          }
        }
      }

      &-right {
        .@{timeline-prefix-cls}-item-content {
          .@{timeline-prefix-cls}-rtl& {
            text-align: left;
          }
        }
      }
    }
  }

  &.@{timeline-prefix-cls}-right {
    .@{timeline-prefix-cls}-item-right {
      .@{timeline-prefix-cls}-item-tail,
      .@{timeline-prefix-cls}-item-head,
      .@{timeline-prefix-cls}-item-head-custom {
        .@{timeline-prefix-cls}-rtl& {
          right: 0;
          left: auto;
        }
      }

      .@{timeline-prefix-cls}-item-content {
        .@{timeline-prefix-cls}-rtl& {
          width: 100%;
          margin-right: 18px;
          text-align: right;
        }
      }
    }
  }

  &&-pending &-item-last &-item-tail {
    .@{timeline-prefix-cls}-rtl& {
      border-right: 2px dotted @timeline-color;
      border-left: none;
    }
  }

  &&-reverse &-item-pending {
    .@{timeline-prefix-cls}-item-tail {
      .@{timeline-prefix-cls}-rtl& {
        border-right: 2px dotted @timeline-color;
        border-left: none;
      }
    }
  }

  &.@{timeline-prefix-cls}-label {
    .@{timeline-prefix-cls}-item-label {
      .@{timeline-prefix-cls}-rtl& {
        text-align: left;
      }
    }
    .@{timeline-prefix-cls}-item-right {
      .@{timeline-prefix-cls}-item-label {
        .@{timeline-prefix-cls}-rtl& {
          right: calc(50% + 14px);
          text-align: right;
        }
      }
    }
  }
}
