.oneui-page-header-back-button {
  border: 0;
  background: transparent;
  padding: 0;
  line-height: inherit;
  display: inline-block;
}

vnpt-page-header,
vnpt-page-header-content,
vnpt-page-header-footer {
  display: block;
}
