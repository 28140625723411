@import '../../style/themes/index';
@import '../../style/mixins/index';
@import './customize.less';

@list-prefix-cls: ~'@{oneui-prefix}-list';

.@{list-prefix-cls} {
  &-rtl {
    direction: rtl;
    text-align: right;

    // fix for virtual scroll style attribute > (direction:ltr)
    .ReactVirtualized__List .@{list-prefix-cls}-item {
      direction: rtl;
    }
  }

  &-pagination {
    .@{list-prefix-cls}-rtl & {
      text-align: left;
    }
  }

  &-item {
    &-meta {
      &-avatar {
        .@{list-prefix-cls}-rtl & {
          margin-right: 0;
          margin-left: @list-item-meta-avatar-margin-right;
        }
      }
    }

    &-action {
      .@{list-prefix-cls}-rtl & {
        margin-right: 48px;
        margin-left: 0;
      }

      & > li:first-child {
        .@{list-prefix-cls}.@{list-prefix-cls}-rtl & {
          padding-right: 0;
          padding-left: @padding-md;
        }
      }

      &-split {
        .@{list-prefix-cls}-rtl & {
          right: auto;
          left: 0;
        }
      }
    }
  }

  &-vertical &-item {
    &-extra {
      .@{list-prefix-cls}-rtl& {
        margin-right: 40px;
        margin-left: 0;
      }
    }

    &-action {
      .@{list-prefix-cls}-rtl& {
        margin-right: auto;
      }

      > li {
        &:first-child {
          .@{list-prefix-cls}-rtl & {
            padding-right: 0;
            padding-left: @padding-md;
          }
        }
      }
    }
  }

  // Horizontal
  &:not(.@{list-prefix-cls}-vertical) {
    .@{list-prefix-cls}-item-no-flex {
      .@{list-prefix-cls}-item-action {
        .@{list-prefix-cls}-rtl & {
          float: left;
        }
      }
    }
  }
}

// responsive
@media screen and (max-width: @screen-md) {
  .@{list-prefix-cls} {
    &-item {
      &-action {
        .@{list-prefix-cls}-rtl & {
          margin-right: 24px;
          margin-left: 0;
        }
      }
    }
  }

  .@{list-prefix-cls}-vertical {
    .@{list-prefix-cls}-item {
      &-extra {
        .@{list-prefix-cls}-rtl & {
          margin-right: 24px;
          margin-left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .@{list-prefix-cls} {
    &-item {
      &-action {
        .@{list-prefix-cls}-rtl & {
          margin-right: 22px;
          margin-left: 0;
        }
      }
    }
  }

  .@{list-prefix-cls}-vertical {
    .@{list-prefix-cls}-item {
      &-extra {
        // to override margins on rtl view
        .@{list-prefix-cls}-rtl& {
          margin: auto auto 16px;
        }
      }
    }
  }
}
