// Fix Input component height issue in IE11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .@{oneui-prefix}-input {
    height: @input-height-base;

    &-lg {
      height: @input-height-lg;
    }

    &-sm {
      height: @input-height-sm;
    }

    &-affix-wrapper {
      > input.@{oneui-prefix}-input {
        height: auto;
      }
    }
  }
}
