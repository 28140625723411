@import '../../style/themes/index';
@import '../../style/mixins/index';

@divider-prefix-cls: ~'@{oneui-prefix}-divider';

.@{divider-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-horizontal&-with-text-left {
    &::before {
      .@{divider-prefix-cls}-rtl& {
        width: 100% - @divider-orientation-margin;
      }
    }
    &::after {
      .@{divider-prefix-cls}-rtl& {
        width: @divider-orientation-margin;
      }
    }
  }

  &-horizontal&-with-text-right {
    &::before {
      .@{divider-prefix-cls}-rtl& {
        width: @divider-orientation-margin;
      }
    }
    &::after {
      .@{divider-prefix-cls}-rtl& {
        width: 100% - @divider-orientation-margin;
      }
    }
  }
  &-horizontal&-with-text-center {
    &::before {
      .@{divider-prefix-cls}-rtl& {
        width: 50%;
      }
    }
    &::after {
      .@{divider-prefix-cls}-rtl& {
        width: 50%;
      }
    }
  }
}
