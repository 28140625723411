@import '../../style/themes/index';
@import '../../style/mixins/index';

@empty-prefix-cls: ~'@{oneui-prefix}-empty';

.@{empty-prefix-cls} {
  &-rtl {
    direction: rtl;
  }
}
