@import './index';

// ================================================================
// =                      Children Component                      =
// ================================================================
.@{form-item-prefix-cls} {
  .@{oneui-prefix}-mentions,
  textarea.@{oneui-prefix}-input {
    height: auto;
  }

  // input[type=file]
  .@{oneui-prefix}-upload {
    background: transparent;
  }
  .@{oneui-prefix}-upload.@{oneui-prefix}-upload-drag {
    background: @background-color-light;
  }

  input[type='radio'],
  input[type='checkbox'] {
    width: 14px;
    height: 14px;
  }

  // Radios and checkboxes on same line
  .@{oneui-prefix}-radio-inline,
  .@{oneui-prefix}-checkbox-inline {
    display: inline-block;
    margin-left: 8px;
    font-weight: normal;
    vertical-align: middle;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }
  }

  .@{oneui-prefix}-checkbox-vertical,
  .@{oneui-prefix}-radio-vertical {
    display: block;
  }

  .@{oneui-prefix}-checkbox-vertical + .@{oneui-prefix}-checkbox-vertical,
  .@{oneui-prefix}-radio-vertical + .@{oneui-prefix}-radio-vertical {
    margin-left: 0;
  }

  .@{oneui-prefix}-input-number {
    + .@{form-prefix-cls}-text {
      margin-left: 8px;
    }
    &-handler-wrap {
      z-index: 2; // https://github.com/oneui-design/oneui-design/issues/6289
    }
  }

  .@{oneui-prefix}-select,
  .@{oneui-prefix}-cascader-picker {
    width: 100%;
  }

  // Don't impact select inside input group
  .@{oneui-prefix}-input-group .@{oneui-prefix}-select,
  .@{oneui-prefix}-input-group .@{oneui-prefix}-cascader-picker {
    width: auto;
  }
}
