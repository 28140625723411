.@{steps-prefix-cls}-navigation {
  padding-top: 12px;

  &.@{steps-prefix-cls}-small {
    .@{steps-prefix-cls}-item {
      &-container {
        margin-left: 12px;
        margin-right: 12px;
        min-width: max-content;
      }
    }
  }

  .@{steps-prefix-cls}-item {
    overflow: visible;
    text-align: center;

    &-container {
      display: inline-block;
      height: 100%;
      margin-left: -16px;
      padding-bottom: 12px;
      text-align: left;
      transition: opacity 0.3s;

      .@{steps-prefix-cls}-item-content {
        max-width: @steps-nav-content-max-width;
      }

      .@{steps-prefix-cls}-item-title {
        max-width: 100%;
        padding-right: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &::after {
          display: none;
        }
      }
    }

    &:not(.@{steps-prefix-cls}-item-active) {
      .@{steps-prefix-cls}-item-container[role='button'] {
        cursor: pointer;
        &:hover {
          opacity: 0.85;
        }
      }
    }

    &:last-child {
      flex: 1;
      &::after {
        display: none;
      }
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 100%;
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-top: -14px;
      margin-left: -2px;
      border: 1px solid @steps-nav-arrow-color;
      border-bottom: none;
      border-left: none;
      transform: rotate(45deg);
      content: '';
    }

    &::before {
      position: absolute;
      bottom: 0;
      left: 50%;
      display: inline-block;
      width: 0;
      height: 1px;
      background-color: @steps-nav-active-color;
      transition: width 0.3s, left 0.3s;
      transition-timing-function: ease-out;
      content: '';
    }
  }

  .@{steps-prefix-cls}-item.@{steps-prefix-cls}-item-active::before {
    left: 0;
    width: 100%;
  }
}

.@{steps-prefix-cls}-navigation.@{steps-prefix-cls}-vertical {
  > .@{steps-prefix-cls}-item {
    margin-right: 0 !important;
    &::before {
      display: none;
    }
    &.@{steps-prefix-cls}-item-active::before {
      top: 0;
      right: 0;
      left: unset;
      display: block;
      width: 3px;
      height: calc(100% - 24px);
    }
    &::after {
      position: relative;
      top: -2px;
      left: 50%;
      display: block;
      width: 8px;
      height: 8px;
      margin-bottom: 8px;
      text-align: center;
      transform: rotate(135deg);
    }
    > .@{steps-prefix-cls}-item-container > .@{steps-prefix-cls}-item-tail {
      visibility: hidden;
    }
  }
}
