.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: @padding-1 !important;
}

.m-2 {
  margin: @padding-2 !important;
}

.m-3 {
  margin: @padding-3 !important;
}

.m-4 {
  margin: @padding-4 !important;
}

.m-5 {
  margin: @padding-5 !important;
}
.m-6 {
  margin: @padding-6 !important;
}

.m-7 {
  margin: @padding-7 !important;
}

.m-8 {
  margin: @padding-8 !important;
}

.m-9 {
  margin: @padding-9 !important;
}

.m-10 {
  margin: @padding-10 !important;
}

.m-11 {
  margin: @padding-11 !important;
}

.m-auto{
  margin: auto !important;
}


//pading left
.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: @padding-1 !important;
}

.ml-2 {
  margin-left: @padding-2 !important;
}

.ml-3 {
  margin-left: @padding-3 !important;
}

.ml-4 {
  margin-left: @padding-4 !important;
}

.ml-5 {
  margin-left: @padding-5 !important;
}
.ml-6 {
  margin-left: @padding-6 !important;
}

.ml-7 {
  margin-left: @padding-7 !important;
}

.ml-8 {
  margin-left: @padding-8 !important;
}

.ml-9 {
  margin-left: @padding-9 !important;
}

.ml-10 {
  margin-left: @padding-10 !important;
}

.ml-11 {
  margin-left: @padding-11 !important;
}

.ml-auto{
  margin-left: auto !important;
}


//padding right
.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: @padding-1 !important;
}

.mr-2 {
  margin-right: @padding-2 !important;
}

.mr-3 {
  margin-right: @padding-3 !important;
}

.mr-4 {
  margin-right: @padding-4 !important;
}

.mr-5 {
  margin-right: @padding-5 !important;
}
.mr-6 {
  margin-right: @padding-6 !important;
}

.mr-7 {
  margin-right: @padding-7 !important;
}

.mr-8 {
  margin-right: @padding-8 !important;
}

.mr-9 {
  margin-right: @padding-9 !important;
}

.mr-10 {
  margin-right: @padding-10 !important;
}

.mr-11 {
  margin-right: @padding-11 !important;
}

.mr-auto{
  margin-right: auto !important;
}



//padding top
.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: @padding-1 !important;
}

.mt-2 {
  margin-top: @padding-2 !important;
}

.mt-3 {
  margin-top: @padding-3 !important;
}

.mt-4 {
  margin-top: @padding-4 !important;
}

.mt-5 {
  margin-top: @padding-5 !important;
}
.mt-6 {
  margin-top: @padding-6 !important;
}

.mt-7 {
  margin-top: @padding-7 !important;
}

.mt-8 {
  margin-top: @padding-8 !important;
}

.mt-9 {
  margin-top: @padding-9 !important;
}

.mt-10 {
  margin-top: @padding-10 !important;
}

.mt-11 {
  margin-top: @padding-11 !important;
}

.mt-auto {
  margin-top: auto !important;
}


//padding bottom
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: @padding-1 !important;
}

.mb-2 {
  margin-bottom: @padding-2 !important;
}

.mb-3 {
  margin-bottom: @padding-3 !important;
}

.mb-4 {
  margin-bottom: @padding-4 !important;
}

.mb-5 {
  margin-bottom: @padding-5 !important;
}
.mb-6 {
  margin-bottom: @padding-6 !important;
}

.mb-7 {
  margin-bottom: @padding-7 !important;
}

.mb-8 {
  margin-bottom: @padding-8 !important;
}

.mb-9 {
  margin-bottom: @padding-9 !important;
}

.mb-10 {
  margin-bottom: @padding-10 !important;
}

.mb-11 {
  margin-bottom: @padding-11 !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

//padding y
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: @padding-1 !important;
  margin-bottom: @padding-1 !important;
}

.my-2 {
  margin-top: @padding-2 !important;
  margin-bottom: @padding-2 !important;
}

.my-3 {
  margin-top: @padding-3 !important;
  margin-bottom: @padding-3 !important;
}

.my-4 {
  margin-top: @padding-4 !important;
  margin-bottom: @padding-4 !important;
}

.my-5 {
  margin-top: @padding-5 !important;
  margin-bottom: @padding-5 !important;
}
.my-6 {
  margin-top: @padding-6 !important;
  margin-bottom: @padding-6 !important;
}

.my-7 {
  margin-top: @padding-7 !important;
  margin-bottom: @padding-7 !important;
}

.my-8 {
  margin-top: @padding-8 !important;
  margin-bottom: @padding-8 !important;
}

.my-9 {
  margin-top: @padding-9 !important;
  margin-bottom: @padding-9 !important;
}

.my-10 {
  margin-top: @padding-10 !important;
  margin-bottom: @padding-10 !important;
}

.my-11 {
  margin-top: @padding-11 !important;
  margin-bottom: @padding-11 !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}


//padding x
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: @padding-1 !important;
  margin-right: @padding-1 !important;
}

.mx-2 {
  margin-left: @padding-2 !important;
  margin-right: @padding-2 !important;
}

.mx-3 {
  margin-left: @padding-3 !important;
  margin-right: @padding-3 !important;
}

.mx-4 {
  margin-left: @padding-4 !important;
  margin-right: @padding-4 !important;
}

.mx-5 {
  margin-left: @padding-5 !important;
  margin-right: @padding-5 !important;
}
.mx-6 {
  margin-left: @padding-6 !important;
  margin-right: @padding-6 !important;
}

.mx-7 {
  margin-left: @padding-7 !important;
  margin-right: @padding-7 !important;
}

.mx-8 {
  margin-left: @padding-8 !important;
  margin-right: @padding-8 !important;
}

.mx-9 {
  margin-left: @padding-9 !important;
  margin-right: @padding-9 !important;
}

.mx-10 {
  margin-left: @padding-10 !important;
  margin-right: @padding-10 !important;
}

.mx-11 {
  margin-left: @padding-11 !important;
  margin-right: @padding-11 !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}


@media (min-width: @screen-sm-min) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: @padding-1 !important;
  }

  .m-sm-2 {
    margin: @padding-2 !important;
  }

  .m-sm-3 {
    margin: @padding-3 !important;
  }

  .m-sm-4 {
    margin: @padding-4 !important;
  }

  .m-sm-5 {
    margin: @padding-5 !important;
  }
  .m-sm-6 {
    margin: @padding-6 !important;
  }

  .m-sm-7 {
    margin: @padding-7 !important;
  }

  .m-sm-8 {
    margin: @padding-8 !important;
  }

  .m-sm-9 {
    margin: @padding-9 !important;
  }

  .m-sm-10 {
    margin: @padding-10 !important;
  }

  .m-sm-11 {
    margin: @padding-11 !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }


  //pading left
  .ml-sm-0 {
    margin-left: 0 !important;
  }

  .ml-sm-1 {
    margin-left: @padding-1 !important;
  }

  .ml-sm-2 {
    margin-left: @padding-2 !important;
  }

  .ml-sm-3 {
    margin-left: @padding-3 !important;
  }

  .ml-sm-4 {
    margin-left: @padding-4 !important;
  }

  .ml-sm-5 {
    margin-left: @padding-5 !important;
  }
  .ml-sm-6 {
    margin-left: @padding-6 !important;
  }

  .ml-sm-7 {
    margin-left: @padding-7 !important;
  }

  .ml-sm-8 {
    margin-left: @padding-8 !important;
  }

  .ml-sm-9 {
    margin-left: @padding-9 !important;
  }

  .ml-sm-10 {
    margin-left: @padding-10 !important;
  }

  .ml-sm-11 {
    margin-left: @padding-11 !important;
  }

  .ml-sm-auto {
    margin-left: auto !important;
  }


  //padding right
  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mr-sm-1 {
    margin-right: @padding-1 !important;
  }

  .mr-sm-2 {
    margin-right: @padding-2 !important;
  }

  .mr-sm-3 {
    margin-right: @padding-3 !important;
  }

  .mr-sm-4 {
    margin-right: @padding-4 !important;
  }

  .mr-sm-5 {
    margin-right: @padding-5 !important;
  }
  .mr-sm-6 {
    margin-right: @padding-6 !important;
  }

  .mr-sm-7 {
    margin-right: @padding-7 !important;
  }

  .mr-sm-8 {
    margin-right: @padding-8 !important;
  }

  .mr-sm-9 {
    margin-right: @padding-9 !important;
  }

  .mr-sm-10 {
    margin-right: @padding-10 !important;
  }

  .mr-sm-11 {
    margin-right: @padding-11 !important;
  }

  .mr-sm-auto {
    margin-right: auto !important;
  }



  //padding top
  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: @padding-1 !important;
  }

  .mt-sm-2 {
    margin-top: @padding-2 !important;
  }

  .mt-sm-3 {
    margin-top: @padding-3 !important;
  }

  .mt-sm-4 {
    margin-top: @padding-4 !important;
  }

  .mt-sm-5 {
    margin-top: @padding-5 !important;
  }
  .mt-sm-6 {
    margin-top: @padding-6 !important;
  }

  .mt-sm-7 {
    margin-top: @padding-7 !important;
  }

  .mt-sm-8 {
    margin-top: @padding-8 !important;
  }

  .mt-sm-9 {
    margin-top: @padding-9 !important;
  }

  .mt-sm-10 {
    margin-top: @padding-10 !important;
  }

  .mt-sm-11 {
    margin-top: @padding-11 !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }


  //padding bottom
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: @padding-1 !important;
  }

  .mb-sm-2 {
    margin-bottom: @padding-2 !important;
  }

  .mb-sm-3 {
    margin-bottom: @padding-3 !important;
  }

  .mb-sm-4 {
    margin-bottom: @padding-4 !important;
  }

  .mb-sm-5 {
    margin-bottom: @padding-5 !important;
  }
  .mb-sm-6 {
    margin-bottom: @padding-6 !important;
  }

  .mb-sm-7 {
    margin-bottom: @padding-7 !important;
  }

  .mb-sm-8 {
    margin-bottom: @padding-8 !important;
  }

  .mb-sm-9 {
    margin-bottom: @padding-9 !important;
  }

  .mb-sm-10 {
    margin-bottom: @padding-10 !important;
  }

  .mb-sm-11 {
    margin-bottom: @padding-11 !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  //padding y
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: @padding-1 !important;
    margin-bottom: @padding-1 !important;
  }

  .my-sm-2 {
    margin-top: @padding-2 !important;
    margin-bottom: @padding-2 !important;
  }

  .my-sm-3 {
    margin-top: @padding-3 !important;
    margin-bottom: @padding-3 !important;
  }

  .my-sm-4 {
    margin-top: @padding-4 !important;
    margin-bottom: @padding-4 !important;
  }

  .my-sm-5 {
    margin-top: @padding-5 !important;
    margin-bottom: @padding-5 !important;
  }
  .my-sm-6 {
    margin-top: @padding-6 !important;
    margin-bottom: @padding-6 !important;
  }

  .my-sm-7 {
    margin-top: @padding-7 !important;
    margin-bottom: @padding-7 !important;
  }

  .my-sm-8 {
    margin-top: @padding-8 !important;
    margin-bottom: @padding-8 !important;
  }

  .my-sm-9 {
    margin-top: @padding-9 !important;
    margin-bottom: @padding-9 !important;
  }

  .my-sm-10 {
    margin-top: @padding-10 !important;
    margin-bottom: @padding-10 !important;
  }

  .my-sm-11 {
    margin-top: @padding-11 !important;
    margin-bottom: @padding-11 !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom:auto !important;
  }

  //padding x
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: @padding-1 !important;
    margin-right: @padding-1 !important;
  }

  .mx-sm-2 {
    margin-left: @padding-2 !important;
    margin-right: @padding-2 !important;
  }

  .mx-sm-3 {
    margin-left: @padding-3 !important;
    margin-right: @padding-3 !important;
  }

  .mx-sm-4 {
    margin-left: @padding-4 !important;
    margin-right: @padding-4 !important;
  }

  .mx-sm-5 {
    margin-left: @padding-5 !important;
    margin-right: @padding-5 !important;
  }
  .mx-sm-6 {
    margin-left: @padding-6 !important;
    margin-right: @padding-6 !important;
  }

  .mx-sm-7 {
    margin-left: @padding-7 !important;
    margin-right: @padding-7 !important;
  }

  .mx-sm-8 {
    margin-left: @padding-8 !important;
    margin-right: @padding-8 !important;
  }

  .mx-sm-9 {
    margin-left: @padding-9 !important;
    margin-right: @padding-9 !important;
  }

  .mx-sm-10 {
    margin-left: @padding-10 !important;
    margin-right: @padding-10 !important;
  }

  .mx-sm-11 {
    margin-left: @padding-11 !important;
    margin-right: @padding-11 !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right:auto !important;
  }
}

@media (min-width: @screen-md-min) {
  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: @padding-1 !important;
  }

  .m-md-2 {
    margin: @padding-2 !important;
  }

  .m-md-3 {
    margin: @padding-3 !important;
  }

  .m-md-4 {
    margin: @padding-4 !important;
  }

  .m-md-5 {
    margin: @padding-5 !important;
  }
  .m-md-6 {
    margin: @padding-6 !important;
  }

  .m-md-7 {
    margin: @padding-7 !important;
  }

  .m-md-8 {
    margin: @padding-8 !important;
  }

  .m-md-9 {
    margin: @padding-9 !important;
  }

  .m-md-10 {
    margin: @padding-10 !important;
  }

  .m-md-11 {
    margin: @padding-11 !important;
  }

  .m-md-auto {
    margin: auto !important;
  }


  //pading left
  .ml-md-0 {
    margin-left: 0 !important;
  }

  .ml-md-1 {
    margin-left: @padding-1 !important;
  }

  .ml-md-2 {
    margin-left: @padding-2 !important;
  }

  .ml-md-3 {
    margin-left: @padding-3 !important;
  }

  .ml-md-4 {
    margin-left: @padding-4 !important;
  }

  .ml-md-5 {
    margin-left: @padding-5 !important;
  }
  .ml-md-6 {
    margin-left: @padding-6 !important;
  }

  .ml-md-7 {
    margin-left: @padding-7 !important;
  }

  .ml-md-8 {
    margin-left: @padding-8 !important;
  }

  .ml-md-9 {
    margin-left: @padding-9 !important;
  }

  .ml-md-10 {
    margin-left: @padding-10 !important;
  }

  .ml-md-11 {
    margin-left: @padding-11 !important;
  }

  .ml-md-auto {
    margin-left: auto !important;
  }


  //padding right
  .mr-md-0 {
    margin-right: 0 !important;
  }

  .mr-md-1 {
    margin-right: @padding-1 !important;
  }

  .mr-md-2 {
    margin-right: @padding-2 !important;
  }

  .mr-md-3 {
    margin-right: @padding-3 !important;
  }

  .mr-md-4 {
    margin-right: @padding-4 !important;
  }

  .mr-md-5 {
    margin-right: @padding-5 !important;
  }
  .mr-md-6 {
    margin-right: @padding-6 !important;
  }

  .mr-md-7 {
    margin-right: @padding-7 !important;
  }

  .mr-md-8 {
    margin-right: @padding-8 !important;
  }

  .mr-md-9 {
    margin-right: @padding-9 !important;
  }

  .mr-md-10 {
    margin-right: @padding-10 !important;
  }

  .mr-md-11 {
    margin-right: @padding-11 !important;
  }

  .mr-md-auto {
    margin-right: auto !important;
  }



  //padding top
  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: @padding-1 !important;
  }

  .mt-md-2 {
    margin-top: @padding-2 !important;
  }

  .mt-md-3 {
    margin-top: @padding-3 !important;
  }

  .mt-md-4 {
    margin-top: @padding-4 !important;
  }

  .mt-md-5 {
    margin-top: @padding-5 !important;
  }
  .mt-md-6 {
    margin-top: @padding-6 !important;
  }

  .mt-md-7 {
    margin-top: @padding-7 !important;
  }

  .mt-md-8 {
    margin-top: @padding-8 !important;
  }

  .mt-md-9 {
    margin-top: @padding-9 !important;
  }

  .mt-md-10 {
    margin-top: @padding-10 !important;
  }

  .mt-md-11 {
    margin-top: @padding-11 !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }


  //padding bottom
  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: @padding-1 !important;
  }

  .mb-md-2 {
    margin-bottom: @padding-2 !important;
  }

  .mb-md-3 {
    margin-bottom: @padding-3 !important;
  }

  .mb-md-4 {
    margin-bottom: @padding-4 !important;
  }

  .mb-md-5 {
    margin-bottom: @padding-5 !important;
  }
  .mb-md-6 {
    margin-bottom: @padding-6 !important;
  }

  .mb-md-7 {
    margin-bottom: @padding-7 !important;
  }

  .mb-md-8 {
    margin-bottom: @padding-8 !important;
  }

  .mb-md-9 {
    margin-bottom: @padding-9 !important;
  }

  .mb-md-10 {
    margin-bottom: @padding-10 !important;
  }

  .mb-md-11 {
    margin-bottom: @padding-11 !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  //padding y
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: @padding-1 !important;
    margin-bottom: @padding-1 !important;
  }

  .my-md-2 {
    margin-top: @padding-2 !important;
    margin-bottom: @padding-2 !important;
  }

  .my-md-3 {
    margin-top: @padding-3 !important;
    margin-bottom: @padding-3 !important;
  }

  .my-md-4 {
    margin-top: @padding-4 !important;
    margin-bottom: @padding-4 !important;
  }

  .my-md-5 {
    margin-top: @padding-5 !important;
    margin-bottom: @padding-5 !important;
  }
  .my-md-6 {
    margin-top: @padding-6 !important;
    margin-bottom: @padding-6 !important;
  }

  .my-md-7 {
    margin-top: @padding-7 !important;
    margin-bottom: @padding-7 !important;
  }

  .my-md-8 {
    margin-top: @padding-8 !important;
    margin-bottom: @padding-8 !important;
  }

  .my-md-9 {
    margin-top: @padding-9 !important;
    margin-bottom: @padding-9 !important;
  }

  .my-md-10 {
    margin-top: @padding-10 !important;
    margin-bottom: @padding-10 !important;
  }

  .my-md-11 {
    margin-top: @padding-11 !important;
    margin-bottom: @padding-11 !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom:auto !important;
  }

  //padding x
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: @padding-1 !important;
    margin-right: @padding-1 !important;
  }

  .mx-md-2 {
    margin-left: @padding-2 !important;
    margin-right: @padding-2 !important;
  }

  .mx-md-3 {
    margin-left: @padding-3 !important;
    margin-right: @padding-3 !important;
  }

  .mx-md-4 {
    margin-left: @padding-4 !important;
    margin-right: @padding-4 !important;
  }

  .mx-md-5 {
    margin-left: @padding-5 !important;
    margin-right: @padding-5 !important;
  }
  .mx-md-6 {
    margin-left: @padding-6 !important;
    margin-right: @padding-6 !important;
  }

  .mx-md-7 {
    margin-left: @padding-7 !important;
    margin-right: @padding-7 !important;
  }

  .mx-md-8 {
    margin-left: @padding-8 !important;
    margin-right: @padding-8 !important;
  }

  .mx-md-9 {
    margin-left: @padding-9 !important;
    margin-right: @padding-9 !important;
  }

  .mx-md-10 {
    margin-left: @padding-10 !important;
    margin-right: @padding-10 !important;
  }

  .mx-md-11 {
    margin-left: @padding-11 !important;
    margin-right: @padding-11 !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right:auto !important;
  }
}



@media (min-width: @screen-lg-min) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: @padding-1 !important;
  }

  .m-lg-2 {
    margin: @padding-2 !important;
  }

  .m-lg-3 {
    margin: @padding-3 !important;
  }

  .m-lg-4 {
    margin: @padding-4 !important;
  }

  .m-lg-5 {
    margin: @padding-5 !important;
  }
  .m-lg-6 {
    margin: @padding-6 !important;
  }

  .m-lg-7 {
    margin: @padding-7 !important;
  }

  .m-lg-8 {
    margin: @padding-8 !important;
  }

  .m-lg-9 {
    margin: @padding-9 !important;
  }

  .m-lg-10 {
    margin: @padding-10 !important;
  }

  .m-lg-11 {
    margin: @padding-11 !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }


  //pading left
  .ml-lg-0 {
    margin-left: 0 !important;
  }

  .ml-lg-1 {
    margin-left: @padding-1 !important;
  }

  .ml-lg-2 {
    margin-left: @padding-2 !important;
  }

  .ml-lg-3 {
    margin-left: @padding-3 !important;
  }

  .ml-lg-4 {
    margin-left: @padding-4 !important;
  }

  .ml-lg-5 {
    margin-left: @padding-5 !important;
  }
  .ml-lg-6 {
    margin-left: @padding-6 !important;
  }

  .ml-lg-7 {
    margin-left: @padding-7 !important;
  }

  .ml-lg-8 {
    margin-left: @padding-8 !important;
  }

  .ml-lg-9 {
    margin-left: @padding-9 !important;
  }

  .ml-lg-10 {
    margin-left: @padding-10 !important;
  }

  .ml-lg-11 {
    margin-left: @padding-11 !important;
  }

  .ml-lg-auto {
    margin-left: auto !important;
  }


  //padding right
  .mr-lg-0 {
    margin-right: 0 !important;
  }

  .mr-lg-1 {
    margin-right: @padding-1 !important;
  }

  .mr-lg-2 {
    margin-right: @padding-2 !important;
  }

  .mr-lg-3 {
    margin-right: @padding-3 !important;
  }

  .mr-lg-4 {
    margin-right: @padding-4 !important;
  }

  .mr-lg-5 {
    margin-right: @padding-5 !important;
  }
  .mr-lg-6 {
    margin-right: @padding-6 !important;
  }

  .mr-lg-7 {
    margin-right: @padding-7 !important;
  }

  .mr-lg-8 {
    margin-right: @padding-8 !important;
  }

  .mr-lg-9 {
    margin-right: @padding-9 !important;
  }

  .mr-lg-10 {
    margin-right: @padding-10 !important;
  }

  .mr-lg-11 {
    margin-right: @padding-11 !important;
  }

  .mr-lg-auto {
    margin-right: auto !important;
  }



  //padding top
  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: @padding-1 !important;
  }

  .mt-lg-2 {
    margin-top: @padding-2 !important;
  }

  .mt-lg-3 {
    margin-top: @padding-3 !important;
  }

  .mt-lg-4 {
    margin-top: @padding-4 !important;
  }

  .mt-lg-5 {
    margin-top: @padding-5 !important;
  }
  .mt-lg-6 {
    margin-top: @padding-6 !important;
  }

  .mt-lg-7 {
    margin-top: @padding-7 !important;
  }

  .mt-lg-8 {
    margin-top: @padding-8 !important;
  }

  .mt-lg-9 {
    margin-top: @padding-9 !important;
  }

  .mt-lg-10 {
    margin-top: @padding-10 !important;
  }

  .mt-lg-11 {
    margin-top: @padding-11 !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }


  //padding bottom
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: @padding-1 !important;
  }

  .mb-lg-2 {
    margin-bottom: @padding-2 !important;
  }

  .mb-lg-3 {
    margin-bottom: @padding-3 !important;
  }

  .mb-lg-4 {
    margin-bottom: @padding-4 !important;
  }

  .mb-lg-5 {
    margin-bottom: @padding-5 !important;
  }
  .mb-lg-6 {
    margin-bottom: @padding-6 !important;
  }

  .mb-lg-7 {
    margin-bottom: @padding-7 !important;
  }

  .mb-lg-8 {
    margin-bottom: @padding-8 !important;
  }

  .mb-lg-9 {
    margin-bottom: @padding-9 !important;
  }

  .mb-lg-10 {
    margin-bottom: @padding-10 !important;
  }

  .mb-lg-11 {
    margin-bottom: @padding-11 !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  //padding y
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: @padding-1 !important;
    margin-bottom: @padding-1 !important;
  }

  .my-lg-2 {
    margin-top: @padding-2 !important;
    margin-bottom: @padding-2 !important;
  }

  .my-lg-3 {
    margin-top: @padding-3 !important;
    margin-bottom: @padding-3 !important;
  }

  .my-lg-4 {
    margin-top: @padding-4 !important;
    margin-bottom: @padding-4 !important;
  }

  .my-lg-5 {
    margin-top: @padding-5 !important;
    margin-bottom: @padding-5 !important;
  }
  .my-lg-6 {
    margin-top: @padding-6 !important;
    margin-bottom: @padding-6 !important;
  }

  .my-lg-7 {
    margin-top: @padding-7 !important;
    margin-bottom: @padding-7 !important;
  }

  .my-lg-8 {
    margin-top: @padding-8 !important;
    margin-bottom: @padding-8 !important;
  }

  .my-lg-9 {
    margin-top: @padding-9 !important;
    margin-bottom: @padding-9 !important;
  }

  .my-lg-10 {
    margin-top: @padding-10 !important;
    margin-bottom: @padding-10 !important;
  }

  .my-lg-11 {
    margin-top: @padding-11 !important;
    margin-bottom: @padding-11 !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom:auto !important;
  }

  //padding x
  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: @padding-1 !important;
    margin-right: @padding-1 !important;
  }

  .mx-lg-2 {
    margin-left: @padding-2 !important;
    margin-right: @padding-2 !important;
  }

  .mx-lg-3 {
    margin-left: @padding-3 !important;
    margin-right: @padding-3 !important;
  }

  .mx-lg-4 {
    margin-left: @padding-4 !important;
    margin-right: @padding-4 !important;
  }

  .mx-lg-5 {
    margin-left: @padding-5 !important;
    margin-right: @padding-5 !important;
  }
  .mx-lg-6 {
    margin-left: @padding-6 !important;
    margin-right: @padding-6 !important;
  }

  .mx-lg-7 {
    margin-left: @padding-7 !important;
    margin-right: @padding-7 !important;
  }

  .mx-lg-8 {
    margin-left: @padding-8 !important;
    margin-right: @padding-8 !important;
  }

  .mx-lg-9 {
    margin-left: @padding-9 !important;
    margin-right: @padding-9 !important;
  }

  .mx-lg-10 {
    margin-left: @padding-10 !important;
    margin-right: @padding-10 !important;
  }

  .mx-lg-11 {
    margin-left: @padding-11 !important;
    margin-right: @padding-11 !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right:auto !important;
  }
}


@media (min-width: @screen-xl-min) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: @padding-1 !important;
  }

  .m-xl-2 {
    margin: @padding-2 !important;
  }

  .m-xl-3 {
    margin: @padding-3 !important;
  }

  .m-xl-4 {
    margin: @padding-4 !important;
  }

  .m-xl-5 {
    margin: @padding-5 !important;
  }
  .m-xl-6 {
    margin: @padding-6 !important;
  }

  .m-xl-7 {
    margin: @padding-7 !important;
  }

  .m-xl-8 {
    margin: @padding-8 !important;
  }

  .m-xl-9 {
    margin: @padding-9 !important;
  }

  .m-xl-10 {
    margin: @padding-10 !important;
  }

  .m-xl-11 {
    margin: @padding-11 !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }


  //pading left
  .ml-xl-0 {
    margin-left: 0 !important;
  }

  .ml-xl-1 {
    margin-left: @padding-1 !important;
  }

  .ml-xl-2 {
    margin-left: @padding-2 !important;
  }

  .ml-xl-3 {
    margin-left: @padding-3 !important;
  }

  .ml-xl-4 {
    margin-left: @padding-4 !important;
  }

  .ml-xl-5 {
    margin-left: @padding-5 !important;
  }
  .ml-xl-6 {
    margin-left: @padding-6 !important;
  }

  .ml-xl-7 {
    margin-left: @padding-7 !important;
  }

  .ml-xl-8 {
    margin-left: @padding-8 !important;
  }

  .ml-xl-9 {
    margin-left: @padding-9 !important;
  }

  .ml-xl-10 {
    margin-left: @padding-10 !important;
  }

  .ml-xl-11 {
    margin-left: @padding-11 !important;
  }

  .ml-xl-auto {
    margin-left: auto !important;
  }


  //padding right
  .mr-xl-0 {
    margin-right: 0 !important;
  }

  .mr-xl-1 {
    margin-right: @padding-1 !important;
  }

  .mr-xl-2 {
    margin-right: @padding-2 !important;
  }

  .mr-xl-3 {
    margin-right: @padding-3 !important;
  }

  .mr-xl-4 {
    margin-right: @padding-4 !important;
  }

  .mr-xl-5 {
    margin-right: @padding-5 !important;
  }
  .mr-xl-6 {
    margin-right: @padding-6 !important;
  }

  .mr-xl-7 {
    margin-right: @padding-7 !important;
  }

  .mr-xl-8 {
    margin-right: @padding-8 !important;
  }

  .mr-xl-9 {
    margin-right: @padding-9 !important;
  }

  .mr-xl-10 {
    margin-right: @padding-10 !important;
  }

  .mr-xl-11 {
    margin-right: @padding-11 !important;
  }

  .mr-xl-auto {
    margin-right: auto !important;
  }



  //padding top
  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: @padding-1 !important;
  }

  .mt-xl-2 {
    margin-top: @padding-2 !important;
  }

  .mt-xl-3 {
    margin-top: @padding-3 !important;
  }

  .mt-xl-4 {
    margin-top: @padding-4 !important;
  }

  .mt-xl-5 {
    margin-top: @padding-5 !important;
  }
  .mt-xl-6 {
    margin-top: @padding-6 !important;
  }

  .mt-xl-7 {
    margin-top: @padding-7 !important;
  }

  .mt-xl-8 {
    margin-top: @padding-8 !important;
  }

  .mt-xl-9 {
    margin-top: @padding-9 !important;
  }

  .mt-xl-10 {
    margin-top: @padding-10 !important;
  }

  .mt-xl-11 {
    margin-top: @padding-11 !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }


  //padding bottom
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: @padding-1 !important;
  }

  .mb-xl-2 {
    margin-bottom: @padding-2 !important;
  }

  .mb-xl-3 {
    margin-bottom: @padding-3 !important;
  }

  .mb-xl-4 {
    margin-bottom: @padding-4 !important;
  }

  .mb-xl-5 {
    margin-bottom: @padding-5 !important;
  }
  .mb-xl-6 {
    margin-bottom: @padding-6 !important;
  }

  .mb-xl-7 {
    margin-bottom: @padding-7 !important;
  }

  .mb-xl-8 {
    margin-bottom: @padding-8 !important;
  }

  .mb-xl-9 {
    margin-bottom: @padding-9 !important;
  }

  .mb-xl-10 {
    margin-bottom: @padding-10 !important;
  }

  .mb-xl-11 {
    margin-bottom: @padding-11 !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  //padding y
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: @padding-1 !important;
    margin-bottom: @padding-1 !important;
  }

  .my-xl-2 {
    margin-top: @padding-2 !important;
    margin-bottom: @padding-2 !important;
  }

  .my-xl-3 {
    margin-top: @padding-3 !important;
    margin-bottom: @padding-3 !important;
  }

  .my-xl-4 {
    margin-top: @padding-4 !important;
    margin-bottom: @padding-4 !important;
  }

  .my-xl-5 {
    margin-top: @padding-5 !important;
    margin-bottom: @padding-5 !important;
  }
  .my-xl-6 {
    margin-top: @padding-6 !important;
    margin-bottom: @padding-6 !important;
  }

  .my-xl-7 {
    margin-top: @padding-7 !important;
    margin-bottom: @padding-7 !important;
  }

  .my-xl-8 {
    margin-top: @padding-8 !important;
    margin-bottom: @padding-8 !important;
  }

  .my-xl-9 {
    margin-top: @padding-9 !important;
    margin-bottom: @padding-9 !important;
  }

  .my-xl-10 {
    margin-top: @padding-10 !important;
    margin-bottom: @padding-10 !important;
  }

  .my-xl-11 {
    margin-top: @padding-11 !important;
    margin-bottom: @padding-11 !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom:auto !important;
  }

  //padding x
  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: @padding-1 !important;
    margin-right: @padding-1 !important;
  }

  .mx-xl-2 {
    margin-left: @padding-2 !important;
    margin-right: @padding-2 !important;
  }

  .mx-xl-3 {
    margin-left: @padding-3 !important;
    margin-right: @padding-3 !important;
  }

  .mx-xl-4 {
    margin-left: @padding-4 !important;
    margin-right: @padding-4 !important;
  }

  .mx-xl-5 {
    margin-left: @padding-5 !important;
    margin-right: @padding-5 !important;
  }
  .mx-xl-6 {
    margin-left: @padding-6 !important;
    margin-right: @padding-6 !important;
  }

  .mx-xl-7 {
    margin-left: @padding-7 !important;
    margin-right: @padding-7 !important;
  }

  .mx-xl-8 {
    margin-left: @padding-8 !important;
    margin-right: @padding-8 !important;
  }

  .mx-xl-9 {
    margin-left: @padding-9 !important;
    margin-right: @padding-9 !important;
  }

  .mx-xl-10 {
    margin-left: @padding-10 !important;
    margin-right: @padding-10 !important;
  }

  .mx-xl-11 {
    margin-left: @padding-11 !important;
    margin-right: @padding-11 !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right:auto !important;
  }
}


@media (min-width: @screen-xxl-min) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: @padding-1 !important;
  }

  .m-xxl-2 {
    margin: @padding-2 !important;
  }

  .m-xxl-3 {
    margin: @padding-3 !important;
  }

  .m-xxl-4 {
    margin: @padding-4 !important;
  }

  .m-xxl-5 {
    margin: @padding-5 !important;
  }
  .m-xxl-6 {
    margin: @padding-6 !important;
  }

  .m-xxl-7 {
    margin: @padding-7 !important;
  }

  .m-xxl-8 {
    margin: @padding-8 !important;
  }

  .m-xxl-9 {
    margin: @padding-9 !important;
  }

  .m-xxl-10 {
    margin: @padding-10 !important;
  }

  .m-xxl-11 {
    margin: @padding-11 !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }


  //pading left
  .ml-xxl-0 {
    margin-left: 0 !important;
  }

  .ml-xxl-1 {
    margin-left: @padding-1 !important;
  }

  .ml-xxl-2 {
    margin-left: @padding-2 !important;
  }

  .ml-xxl-3 {
    margin-left: @padding-3 !important;
  }

  .ml-xxl-4 {
    margin-left: @padding-4 !important;
  }

  .ml-xxl-5 {
    margin-left: @padding-5 !important;
  }
  .ml-xxl-6 {
    margin-left: @padding-6 !important;
  }

  .ml-xxl-7 {
    margin-left: @padding-7 !important;
  }

  .ml-xxl-8 {
    margin-left: @padding-8 !important;
  }

  .ml-xxl-9 {
    margin-left: @padding-9 !important;
  }

  .ml-xxl-10 {
    margin-left: @padding-10 !important;
  }

  .ml-xxl-11 {
    margin-left: @padding-11 !important;
  }

  .ml-xxl-auto {
    margin-left: auto !important;
  }


  //padding right
  .mr-xxl-0 {
    margin-right: 0 !important;
  }

  .mr-xxl-1 {
    margin-right: @padding-1 !important;
  }

  .mr-xxl-2 {
    margin-right: @padding-2 !important;
  }

  .mr-xxl-3 {
    margin-right: @padding-3 !important;
  }

  .mr-xxl-4 {
    margin-right: @padding-4 !important;
  }

  .mr-xxl-5 {
    margin-right: @padding-5 !important;
  }
  .mr-xxl-6 {
    margin-right: @padding-6 !important;
  }

  .mr-xxl-7 {
    margin-right: @padding-7 !important;
  }

  .mr-xxl-8 {
    margin-right: @padding-8 !important;
  }

  .mr-xxl-9 {
    margin-right: @padding-9 !important;
  }

  .mr-xxl-10 {
    margin-right: @padding-10 !important;
  }

  .mr-xxl-11 {
    margin-right: @padding-11 !important;
  }

  .mr-xxl-auto {
    margin-right: auto !important;
  }



  //padding top
  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: @padding-1 !important;
  }

  .mt-xxl-2 {
    margin-top: @padding-2 !important;
  }

  .mt-xxl-3 {
    margin-top: @padding-3 !important;
  }

  .mt-xxl-4 {
    margin-top: @padding-4 !important;
  }

  .mt-xxl-5 {
    margin-top: @padding-5 !important;
  }
  .mt-xxl-6 {
    margin-top: @padding-6 !important;
  }

  .mt-xxl-7 {
    margin-top: @padding-7 !important;
  }

  .mt-xxl-8 {
    margin-top: @padding-8 !important;
  }

  .mt-xxl-9 {
    margin-top: @padding-9 !important;
  }

  .mt-xxl-10 {
    margin-top: @padding-10 !important;
  }

  .mt-xxl-11 {
    margin-top: @padding-11 !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }


  //padding bottom
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: @padding-1 !important;
  }

  .mb-xxl-2 {
    margin-bottom: @padding-2 !important;
  }

  .mb-xxl-3 {
    margin-bottom: @padding-3 !important;
  }

  .mb-xxl-4 {
    margin-bottom: @padding-4 !important;
  }

  .mb-xxl-5 {
    margin-bottom: @padding-5 !important;
  }
  .mb-xxl-6 {
    margin-bottom: @padding-6 !important;
  }

  .mb-xxl-7 {
    margin-bottom: @padding-7 !important;
  }

  .mb-xxl-8 {
    margin-bottom: @padding-8 !important;
  }

  .mb-xxl-9 {
    margin-bottom: @padding-9 !important;
  }

  .mb-xxl-10 {
    margin-bottom: @padding-10 !important;
  }

  .mb-xxl-11 {
    margin-bottom: @padding-11 !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  //padding y
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: @padding-1 !important;
    margin-bottom: @padding-1 !important;
  }

  .my-xxl-2 {
    margin-top: @padding-2 !important;
    margin-bottom: @padding-2 !important;
  }

  .my-xxl-3 {
    margin-top: @padding-3 !important;
    margin-bottom: @padding-3 !important;
  }

  .my-xxl-4 {
    margin-top: @padding-4 !important;
    margin-bottom: @padding-4 !important;
  }

  .my-xxl-5 {
    margin-top: @padding-5 !important;
    margin-bottom: @padding-5 !important;
  }
  .my-xxl-6 {
    margin-top: @padding-6 !important;
    margin-bottom: @padding-6 !important;
  }

  .my-xxl-7 {
    margin-top: @padding-7 !important;
    margin-bottom: @padding-7 !important;
  }

  .my-xxl-8 {
    margin-top: @padding-8 !important;
    margin-bottom: @padding-8 !important;
  }

  .my-xxl-9 {
    margin-top: @padding-9 !important;
    margin-bottom: @padding-9 !important;
  }

  .my-xxl-10 {
    margin-top: @padding-10 !important;
    margin-bottom: @padding-10 !important;
  }

  .my-xxl-11 {
    margin-top: @padding-11 !important;
    margin-bottom: @padding-11 !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom:auto !important;
  }

  //padding x
  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: @padding-1 !important;
    margin-right: @padding-1 !important;
  }

  .mx-xxl-2 {
    margin-left: @padding-2 !important;
    margin-right: @padding-2 !important;
  }

  .mx-xxl-3 {
    margin-left: @padding-3 !important;
    margin-right: @padding-3 !important;
  }

  .mx-xxl-4 {
    margin-left: @padding-4 !important;
    margin-right: @padding-4 !important;
  }

  .mx-xxl-5 {
    margin-left: @padding-5 !important;
    margin-right: @padding-5 !important;
  }
  .mx-xxl-6 {
    margin-left: @padding-6 !important;
    margin-right: @padding-6 !important;
  }

  .mx-xxl-7 {
    margin-left: @padding-7 !important;
    margin-right: @padding-7 !important;
  }

  .mx-xxl-8 {
    margin-left: @padding-8 !important;
    margin-right: @padding-8 !important;
  }

  .mx-xxl-9 {
    margin-left: @padding-9 !important;
    margin-right: @padding-9 !important;
  }

  .mx-xxl-10 {
    margin-left: @padding-10 !important;
    margin-right: @padding-10 !important;
  }

  .mx-xxl-11 {
    margin-left: @padding-11 !important;
    margin-right: @padding-11 !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right:auto !important;
  }
}