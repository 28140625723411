vnpt-table,
vnpt-table-title-footer,
vnpt-table-inner-scroll,
vnpt-table-inner-default,
vnpt-table-selection {
  display: block;
}

.vnpt-table-out-bordered {
  > .@{table-prefix-cls}-title {
    border: @table-border;
    border-bottom: 0;
  }
  > .@{table-prefix-cls}-container {
    border: @table-border;
    border-bottom: 0;
  }
  > .@{table-prefix-cls}-footer {
    border: @table-border;
    border-top: 0;
  }
}

cdk-virtual-scroll-viewport.oneui-table-body {
  overflow-y: scroll;
}

.vnpt-table-hide-scrollbar {
  scrollbar-color: @table-header-bg @table-header-bg;
  &::-webkit-scrollbar {
    background-color: @table-header-bg;
  }
}

.@{table-prefix-cls}.@{table-prefix-cls}-small {
  .vnpt-table-hide-scrollbar {
    scrollbar-color: @table-header-bg-sm @table-header-bg-sm;
    &::-webkit-scrollbar {
      background-color: transparent;
    }
  }
}

.oneui-table-wrapper-rtl .oneui-table thead > tr > th.oneui-table-selection-column {
  text-align: center;
}
