// mixins for button
// ------------------------
.button-size(@height; @padding-horizontal; @font-size; @border-radius) {
  @padding-vertical: max((round(((@height - @font-size * @line-height-base) / 2) * 10) / 10) - @border-width-base,
    0);
  height: @height;
  padding: @padding-vertical @padding-horizontal;
  font-size: @font-size;
  border-radius: @border-radius;
}

.button-disabled(@color: @btn-disable-color; @background: @btn-disable-bg; @border: @btn-disable-border) {
  &[disabled] {

    &,
    &:hover,
    &:focus,
    &:active {
      .button-color(@color; @background; @border);

      text-shadow: none;
      box-shadow: none;
    }
  }
}

.button-variant-primary(@color; @background) {
  .button-color(@color; @background; @background);

  text-shadow: @btn-text-shadow;
  box-shadow: @btn-primary-shadow;

  &:hover,
  &:focus {
    & when (@theme =dark) {
      .button-color(@color;  @color-btn-hover;  @color-btn-hover);
    }

    & when not (@theme =dark) {
      .button-color(@color; @color-btn-hover; @color-btn-hover);
    }
  }

  &:active {
    & when (@theme =dark) {
      .button-color(@color; ~`colorPalette('@{background}', 5) `; ~`colorPalette('@{background}', 5) `);
    }

    & when not (@theme =dark) {
      .button-color(@color;  @color-btn-hover;  @color-btn-hover);
    }
  }

  .button-disabled();
}

.button-variant-primary-danger(@color; @background) {
  .button-color(@color; @background; @background);

  text-shadow: @btn-text-shadow;
  box-shadow: @btn-primary-shadow;

  &:hover,
  &:focus {
    & when (@theme =dark) {
      .button-color(@color;  @color-btn-danger-hover;  @color-btn-danger-hover);
    }

    & when not (@theme =dark) {
      .button-color(@color; @color-btn-danger-hover; @color-btn-danger-hover);
    }
  }

  &:active {
    & when (@theme =dark) {
      .button-color(@color; ~`colorPalette('@{background}', 5) `; ~`colorPalette('@{background}', 5) `);
    }

    & when not (@theme =dark) {
      .button-color(@color;  @color-btn-hover;  @color-btn-hover);
    }
  }

  .button-disabled();
}

.button-variant-other(@color; @background; @border) {
  .button-color(@color; @background; @border);

  &:hover,
  &:focus {
    & when (@theme =dark) {
      .button-color(@primary-5; @background; @primary-5);
    }

    & when not (@theme =dark) {
      .button-color(~`colorPalette('@{btn-primary-bg}', 5) `; @background;
        ~`colorPalette('@{btn-primary-bg}', 5) `);
    }
  }

  &:active {
    & when (@theme =dark) {
      .button-color(@primary-7; @background; @primary-7);
    }

    & when not (@theme =dark) {
      .button-color(~`colorPalette('@{btn-primary-bg}', 7) `; @background;
        ~`colorPalette('@{btn-primary-bg}', 7) `);
    }
  }

  .button-disabled();
}

.button-variant-ghost(@color; @border: @color) {
  .button-color(@color; transparent; @border);
  text-shadow: none;

  &:hover,
  &:focus {
    & when (@border =transparent) {
      & when (@theme =dark) {
        .button-color(~`colorPalette('@{color}', 7) `; transparent; transparent);
      }

      & when not (@theme =dark) {
        .button-color(~`colorPalette('@{color}', 5) `; transparent; transparent);
      }
    }

    & when not (@border =transparent) {
      & when (@theme =dark) {
        .button-color(~`colorPalette('@{color}', 7) `; transparent; ~`colorPalette('@{color}', 7) `);
      }

      & when not (@theme =dark) {
        .button-color(~`colorPalette('@{color}', 5) `; transparent; ~`colorPalette('@{color}', 5) `);
      }
    }
  }

  &:active {
    & when (@border =transparent) {
      & when (@theme =dark) {
        .button-color(~`colorPalette('@{color}', 5) `; transparent; transparent);
      }

      & when not (@theme =dark) {
        .button-color(~`colorPalette('@{color}', 7) `; transparent; transparent);
      }
    }

    & when not(@border =transparent) {
      & when (@theme =dark) {
        .button-color(~`colorPalette('@{color}', 5) `; transparent; ~`colorPalette('@{color}', 5) `);
      }

      & when not (@theme =dark) {
        .button-color(~`colorPalette('@{color}', 7) `; transparent; ~`colorPalette('@{color}', 7) `);
      }
    }
  }

  .button-disabled();
}

.button-color(@color; @background; @border) {
  color: @color;
  background: @background;
  border-color: @border; // a inside Button which only work in Chrome

  // http://stackoverflow.com/a/17253457
  >a:only-child {
    color: currentColor;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: transparent;
      content: '';
    }
  }
}

.button-group-base(@btnClassName) {
  position: relative;
  display: inline-flex;

  >.@{btnClassName},
  >span>.@{btnClassName} {
    position: relative;

    &:hover,
    &:focus,
    &:active {
      z-index: 2;
    }

    &[disabled] {
      z-index: 0;
    }
  }

  .@{btnClassName}-icon-only {
    font-size: @font-size-base;
  }

  // size
  &-lg>.@{btnClassName},
  &-lg>span>.@{btnClassName} {
    .button-size(@btn-height-lg; @btn-padding-horizontal-lg; @btn-font-size-lg; 0);
  }

  &-lg .@{btnClassName}.@{btnClassName}-icon-only {
    .square(@btn-height-lg);
    padding-right: 0;
    padding-left: 0;
  }

  &-sm>.@{btnClassName},
  &-sm>span>.@{btnClassName} {
    .button-size(@btn-height-sm; @btn-padding-horizontal-sm; @font-size-base; 0);

    >.@{iconfont-css-prefix} {
       font-size: @font-size-base - 2px;

    }
  }

  &-sm .@{btnClassName}.@{btnClassName}-icon-only {
    .square(@btn-height-sm);
    padding-right: 0;
    padding-left: 0;
  }
}

// Base styles of buttons
// --------------------------------------------------
.btn() {
  position: relative;
  display: inline-block;
  font-weight: @btn-font-weight;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  box-sizing: border-box;
  border: @btn-border-width @btn-border-style @primary-color;
  box-shadow: @btn-shadow;
  cursor: pointer;
  transition: all 0.3s @ease-in-out;
  user-select: none;
  touch-action: manipulation;
  .button-size(@btn-height-base; @btn-padding-horizontal-base; @font-size-base; @btn-border-radius-base );

  >.@{iconfont-css-prefix} {
    line-height: 1;
  }

  &,
  &:active,
  &:focus {
    outline: 0;
  }

  &:not([disabled]):hover {
    text-decoration: none;
  }

  &:not([disabled]):active {
    outline: 0;
    box-shadow: none;
  }

  &[disabled] {
    cursor: not-allowed;

    >* {
      pointer-events: none;
    }
  }

  &-lg {
    .button-size(@btn-height-lg; @btn-padding-horizontal-lg; @btn-font-size-lg; @btn-border-radius-base );
  }

  &-sm {
    .button-size(@btn-height-sm; @btn-padding-horizontal-sm; @btn-font-size-sm; @btn-border-radius-sm );

    .@{iconfont-css-prefix} {
        font-size: @font-size-base;
    }
  }
}

// primary button style
.btn-primary() {
  .button-variant-primary(@btn-primary-color; @btn-primary-bg);
}

// default button style
.btn-default() {
  .button-variant-other(@btn-default-color; @btn-default-bg; @btn-default-border);

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    background:  @btn-default-bg;
    color: @color-btn-hover;
    border-color: @color-btn-hover;
  }
}

// ghost button style
.btn-ghost() {
  .button-variant-other(@btn-ghost-color, @btn-ghost-bg, @btn-ghost-border);
}

// dashed button style
.btn-dashed() {
  .button-variant-other(@btn-default-color, @btn-default-bg, @btn-default-border);
  border-style: dashed;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    border-color:  @color-btn-hover;
    color: @color-btn-hover;
  }
}

// danger button style
.btn-danger() {
  .button-variant-primary-danger(@btn-danger-color, @btn-danger-bg);
}

// danger default button style
.btn-danger-default() {
  .button-color(@error-color, @btn-default-bg, @error-color);

  &:hover,
  &:focus {
    & when (@theme =dark) {
      .button-color(@color-btn-danger-hover; @btn-default-bg; @color-btn-danger-hover);
    }

    & when not (@theme =dark) {
      .button-color(@color-btn-danger-hover; @btn-default-bg; @color-btn-danger-hover);
    }
  }

  &:active {
    & when (@theme =dark) {
      .button-color(~`colorPalette('@{error-color}', 5) `; @btn-default-bg; ~`colorPalette('@{error-color}', 5) `);
    }

    & when not (@theme =dark) {
      .button-color(~`colorPalette('@{error-color}', 7) `; @btn-default-bg; ~`colorPalette('@{error-color}', 7) `);
    }
  }

  .button-disabled();
}

// danger link button style
.btn-danger-link() {
  .button-variant-other(@error-color, transparent, transparent);
  box-shadow: none;

  &:hover,
  &:focus {
    & when (@theme =dark) {
      .button-color(@color-btn-danger-hover; transparent; transparent);
    }

    & when not (@theme =dark) {
      .button-color(@color-btn-danger-hover; transparent; transparent);
    }
  }

  &:active {
    & when (@theme =dark) {
      .button-color(~`colorPalette('@{error-color}', 5) `; transparent; transparent);
    }

    & when not (@theme =dark) {
      .button-color(~`colorPalette('@{error-color}', 7) `; transparent; transparent);
    }
  }

  .button-disabled(@disabled-color; transparent; transparent);
}

// link button style
.btn-link() {
  .button-variant-other(@link-color, transparent, transparent);
  box-shadow: none;

  &:hover {
    background: @btn-link-hover-bg;
    color: @color-btn-hover;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }

  .button-disabled(@disabled-color; transparent; transparent);

  span {
    text-decoration: underline;
  }
}

// text button style
.btn-text() {
  .button-variant-other(@text-color, transparent, transparent);
  box-shadow: none;

  &:hover,
  &:focus {
    color: @color-btn-hover;
    background: @btn-text-hover-bg;
    border-color: transparent;
  }

  &:active {
    color: @color-btn-hover;
    background: fadein(@btn-text-hover-bg, 1%);
    border-color: transparent;
  }

  .button-disabled(@disabled-color; transparent; transparent);
}

.btn-danger-text() {
  .button-variant-other(@error-color, transparent, transparent);
  box-shadow: none;

  &:hover,
  &:focus {
    & when (@theme =dark) {
      .button-color(@color-btn-danger-hover; @btn-text-hover-bg; transparent);
    }

    & when not (@theme =dark) {
      .button-color(@color-btn-danger-hover; @btn-text-hover-bg; transparent);
    }
  }

  &:active {
    & when (@theme =dark) {
      .button-color(~`colorPalette('@{error-color}', 5) `; fadein(@btn-text-hover-bg, 1%); transparent);
    }

    & when not (@theme =dark) {
      .button-color(~`colorPalette('@{error-color}', 7) `; fadein(@btn-text-hover-bg, 1%); transparent);
    }
  }

  .button-disabled(@disabled-color; transparent; transparent);
}

// round button
.btn-round(@btnClassName: btn) {
  .button-size(@btn-circle-size; (@btn-circle-size / 2); @font-size-base; @btn-circle-size);

  &.@{btnClassName}-lg {
    .button-size(@btn-circle-size-lg; (@btn-circle-size-lg / 2); @btn-font-size-lg; @btn-circle-size-lg );
  }

  &.@{btnClassName}-sm {
    .button-size(@btn-circle-size-sm; (@btn-circle-size-sm / 2); @font-size-base; @btn-circle-size-sm );
  }
}

// square button: the content only contains icon
.btn-square(@btnClassName: btn) {
  .square(@btn-square-size);
  .button-size(@btn-square-size; 0; @btn-square-only-icon-size; @btn-border-radius-base);

  &>* {
    font-size: @btn-square-only-icon-size;
  }

  &.@{btnClassName}-lg {
    .square(@btn-square-size-lg);
    .button-size(@btn-square-size-lg; 0; @btn-square-only-icon-size-lg; @btn-border-radius-base);

    &>* {
      font-size: @btn-square-only-icon-size-lg;
    }
  }

  &.@{btnClassName}-sm {
    .square(@btn-square-size-sm);
    .button-size(@btn-square-size-sm; 0; @btn-square-only-icon-size-sm; @btn-border-radius-base);

    &>* {
      font-size: @btn-square-only-icon-size-sm;
    }
  }
}

// circle button: the content only contains icon
.btn-circle(@btnClassName: btn) {
  min-width: @btn-height-base;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;

  &.@{btnClassName}-lg {
    min-width: @btn-height-lg;
    border-radius: 50%;
  }

  &.@{btnClassName}-sm {
    min-width: @btn-height-sm;
    border-radius: 50%;
  }
}

// Horizontal button groups style
// --------------------------------------------------
.btn-group(@btnClassName: btn) {
  .button-group-base(@btnClassName);

  .@{btnClassName}+.@{btnClassName},
  .@{btnClassName}+&,
  span+.@{btnClassName},
  .@{btnClassName}+span,
  >span+span,
  &+.@{btnClassName},
  &+& {
    margin-left: -1px;
  }

  .@{btnClassName}-primary+.@{btnClassName}:not(.@{btnClassName}-primary):not([disabled]) {
    border-left-color: transparent;
  }

  .@{btnClassName} {
    border-radius: 0;
  }

  >.@{btnClassName}:first-child,
  >span:first-child>.@{btnClassName} {
    margin-left: 0;
  }

  >.@{btnClassName}:only-child {
    border-radius: @btn-border-radius-base;
  }

  >span:only-child>.@{btnClassName} {
    border-radius: @btn-border-radius-base;
  }

  >.@{btnClassName}:first-child:not(:last-child),
  >span:first-child:not(:last-child)>.@{btnClassName} {
    border-top-left-radius: @btn-border-radius-base;
    border-bottom-left-radius: @btn-border-radius-base;
  }

  >.@{btnClassName}:last-child:not(:first-child),
  >span:last-child:not(:first-child)>.@{btnClassName} {
    border-top-right-radius: @btn-border-radius-base;
    border-bottom-right-radius: @btn-border-radius-base;
  }

  &-sm {
    >.@{btnClassName}:only-child {
      border-radius: @btn-border-radius-sm;
    }

    >span:only-child>.@{btnClassName} {
      border-radius: @btn-border-radius-sm;
    }

    >.@{btnClassName}:first-child:not(:last-child),
    >span:first-child:not(:last-child)>.@{btnClassName} {
      border-top-left-radius: @btn-border-radius-sm;
      border-bottom-left-radius: @btn-border-radius-sm;
    }

    >.@{btnClassName}:last-child:not(:first-child),
    >span:last-child:not(:first-child)>.@{btnClassName} {
      border-top-right-radius: @btn-border-radius-sm;
      border-bottom-right-radius: @btn-border-radius-sm;
    }
  }

  &>& {
    float: left;
  }

  &>&:not(:first-child):not(:last-child)>.@{btnClassName} {
    border-radius: 0;
  }

  &>&:first-child:not(:last-child) {
    >.@{btnClassName}:last-child {
      padding-right: 8px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &>&:last-child:not(:first-child)>.@{btnClassName}:first-child {
    padding-left: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}