@import '../../style/themes/index';
@import '../../style/mixins/index';
@import '../../checkbox/style/mixin';

@transfer-prefix-cls: ~'@{oneui-prefix}-transfer';

.@{transfer-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-list {
    &-search {
      .@{transfer-prefix-cls}-rtl & {
        padding-right: @control-padding-horizontal-sm;
        padding-left: 24px;
      }
      &-action {
        .@{transfer-prefix-cls}-rtl & {
          right: auto;
          left: 12px;
        }
      }
    }

    &-header {
      > *:not(:last-child) {
        .@{transfer-prefix-cls}-rtl & {
          margin-right: 0;
          margin-left: 4px;
        }
      }

      .@{transfer-prefix-cls}-rtl & {
        right: 0;
        left: auto;
      }
      &-title {
        .@{transfer-prefix-cls}-rtl & {
          text-align: left;
        }
      }
    }

    &-content {
      &-item {
        > *:not(:last-child) {
          .@{transfer-prefix-cls}-rtl & {
            margin-right: 0;
            margin-left: 8px;
          }
        }
      }
    }

    &-pagination {
      .@{transfer-prefix-cls}-rtl & {
        text-align: left;
      }
    }

    &-footer {
      .@{transfer-prefix-cls}-rtl & {
        right: 0;
        left: auto;
      }
    }
  }
}
