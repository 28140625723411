.oneui-select-dropdown {
  top: 100%;
  left: 0;
  position: relative;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  display: block;

  .cdk-virtual-scroll-content-wrapper {
    right: 0;
  }
  .full-width {
    contain: initial;
    .cdk-virtual-scroll-content-wrapper {
      position: static;
    }
  }
}
