@import '../../style/themes/index';
@import '../../style/mixins/index';

@message-prefix-cls: ~'@{oneui-prefix}-message';

.@{message-prefix-cls}-rtl {
  direction: rtl;

  span {
    direction: rtl;
  }

  .@{iconfont-css-prefix} {
    margin-right: 0;
    margin-left: 8px;
  }
}
