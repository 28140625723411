@import './themes/default.less';

// cdk overlay
.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1000;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-backdrop {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
  opacity: 0;
  position: absolute;
  pointer-events: auto;
  z-index: 1000;

  &.oneui-modal-mask {
    opacity: 1;
  }
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  z-index: 1000; // Give an opportunity to the content own to manage their z-index such as Modal
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

// Used when disabling global scrolling.
.cdk-global-scrollblock {
  position: fixed;

  // Necessary for the content not to lose its width. Note that we're using 100%, instead of
  // 100vw, because 100vw includes the width plus the scrollbar, whereas 100% is the width
  // that the element had before we made it `fixed`.
  width: 100%;

  // Note: this will always add a scrollbar to whatever element it is on, which can
  // potentially result in double scrollbars. It shouldn't be an issue, because we won't
  // block scrolling on a page that doesn't have a scrollbar in the first place.
  overflow-y: scroll;

  // https://github.com/angular/material2/issues/15051
  body {
    overflow-x: visible;
  }
}

.vnpt-overlay-transparent-backdrop {
  &, &.cdk-overlay-backdrop-showing {
    opacity: 0;
  }
}

.vnpt-animate-disabled {
  // drawer
  &.oneui-drawer {
    &.oneui-drawer-open .oneui-drawer-mask {
      animation: none;
      transition: none;
    }

    & > * {
      transition: none;
    }
  }

  // modal
  .oneui-modal-mask, .oneui-modal {
    animation: none;
    transition: none;

    &.zoom-enter, &.zoom-leave,
    &.zoom-enter-active, &.zoom-leave-active {
      animation: none;
      transition: none;
    }
  }

  // menu
  &.oneui-menu {
    transition: none;

    .oneui-menu-item, .oneui-menu-submenu-title {
      transition: none;
    }

    .oneui-menu-item .ms-Icon, .oneui-menu-submenu-title .ms-Icon {
      transition: none;

      & + span {
        transition: none;
      }
    }
  }

  // tabs
  &.oneui-tabs {
    .oneui-tabs-top-content.oneui-tabs-content-animated,
    .oneui-tabs-bottom-content.oneui-tabs-content-animated,
    .oneui-tabs-top-content > .oneui-tabs-tabpane,
    .oneui-tabs-bottom-content > .oneui-tabs-tabpane,
    &.oneui-tabs-left .oneui-tabs-ink-bar-animated,
    &.oneui-tabs-right .oneui-tabs-ink-bar-animated,
    &.oneui-tabs-top .oneui-tabs-ink-bar-animated,
    &.oneui-tabs-bottom .oneui-tabs-ink-bar-animated {
      transition: none;
    }
  }

  // collapse
  &.oneui-collapse > .oneui-collapse-item > .oneui-collapse-header .oneui-collapse-arrow {
    transition: none;
  }
}
