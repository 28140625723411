// Compatibility for browsers.

// Placeholder text
.placeholder(@color: @input-placeholder-color) {
  // Firefox
  &::-moz-placeholder {
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }

  &::placeholder {
    color: @color;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
    font-style: italic;
    color: rgba(32, 31, 30, 0.8) !important;
  }
}
