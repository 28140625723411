@import '../themes/index';
@import '../mixins/iconfont';

.@{iconfont-css-prefix} {
  .iconfont-mixin();

  font-size: @btn-square-only-icon-size;

  &[tabindex] {
    cursor: pointer;
  }

  .imgIcon-xs{
    height: @height-base - 14px;
    vertical-align: inherit;
  }
  .imgIcon-sm{
    height: @height-base - 10px;
    vertical-align: inherit;
  }
  .imgIcon-regular{
    height: @height-base - 6px;
    vertical-align: inherit;
  }
  .imgIcon-md{
    height: @height-base + 2px;
    vertical-align: inherit;
  }
  .imgIcon-lg{
    height: @height-base + 8px;
    vertical-align: inherit;
  }
  .imgIcon-xl{
    height: @height-base + 20px;
    vertical-align: inherit;
  }
  .imgIcon-xxl{
    height: @height-base + 32px;
    vertical-align: inherit;
  }


}

.@{iconfont-css-prefix}-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.@{iconfont-css-prefix}-spin {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}

.@{iconfont-css-prefix}-xs {
  display: inline-block;
  font-size: @font-size-base - 2px !important;
}

.@{iconfont-css-prefix}-sm {
  display: inline-block;
  font-size: @font-size-base !important;
}

.@{iconfont-css-prefix}-md {
  display: inline-block;
  font-size: @btn-square-only-icon-size-lg + 2px !important;
}

.@{iconfont-css-prefix}-lg {
  display: inline-block;
  font-size: @btn-square-only-icon-size-lg + 8px  !important;
}

.@{iconfont-css-prefix}-xl {
  display: inline-block;
  font-size: @btn-square-only-icon-size-lg + 14px  !important;
}

.@{iconfont-css-prefix}-xxl {
  display: inline-block;
  font-size: @btn-square-only-icon-size-lg + 24px  !important;
}

.@{iconfont-css-prefix}-color-success {
  display: inline-block;
  color: @green-base !important;
}

.@{iconfont-css-prefix}-color-danger {
  display: inline-block;
  color: @red-base !important;
}

.@{iconfont-css-prefix}-color-warning {
  display: inline-block;
  color: @orange-base !important;
}

.@{iconfont-css-prefix}-color-primary {
  display: inline-block;
  color: @blue-base !important;
}

.@{iconfont-css-prefix}-color-black {
  display: inline-block;
  color: @black !important;
}

.@{iconfont-css-prefix}-color-white {
  display: inline-block;
  color: @white !important;
}

.@{iconfont-css-prefix}--loading::before {
  content: "\EDF6";
}
