@font-face {
    font-family: 'gomeet-icons';
    src: url('../../assets/font/gomeet-icons/gomeet-icons.eot');
    src: url('../../assets/font/gomeet-icons/gomeet-icons.eot?#iefix') format('embedded-opentype'),
        url('../../assets/font/gomeet-icons/gomeet-icons.woff2') format('woff2'),
        url('../../assets/font/gomeet-icons/gomeet-icons.woff') format('woff'),
        url('../../assets/font/gomeet-icons/gomeet-icons.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-gomeet-"],
[class*=" icon-gomeet-"] {
    font-family: 'gomeet-icons' !important;
    speak: none;
    position: relative;
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    width: 1em;
    text-align: center;
    vertical-align: middle;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-gomeet-breakout:before {
    content: "\e900";
}

.icon-gomeet-chat:before {
    content: "\e901";
}

.icon-gomeet-chat2:before {
    content: "\e902";
}

.icon-gomeet-check:before {
    content: "\e903";
}

.icon-gomeet-chevron-down:before {
    content: "\e904";
}

.icon-gomeet-chevron-left:before {
    content: "\e905";
}

.icon-gomeet-chevron-right:before {
    content: "\e906";
}

.icon-gomeet-chevron-up:before {
    content: "\e907";
}

.icon-gomeet-circle-close:before {
    content: "\e908";
}

.icon-gomeet-delete:before {
    content: "\e909";
}

.icon-gomeet-file:before {
    content: "\e90a";
}

.icon-gomeet-file-excel:before {
    content: "\e90b";
}

.icon-gomeet-file-pdf:before {
    content: "\e90c";
}

.icon-gomeet-file-powerpoint:before {
    content: "\e90d";
}

.icon-gomeet-file-text:before {
    content: "\e90e";
}

.icon-gomeet-file-word:before {
    content: "\e90f";
}

.icon-gomeet-group:before {
    content: "\e910";
}

.icon-gomeet-lock:before {
    content: "\e911";
}

.icon-gomeet-more:before {
    content: "\e912";
}

.icon-gomeet-morevertical:before {
    content: "\e913";
}

.icon-gomeet-mute:before {
    content: "\e914";
}

.icon-gomeet-mute-filled:before {
    content: "\e915";
}

.icon-gomeet-participants:before {
    content: "\e916";
}

.icon-gomeet-pin:before {
    content: "\e917";
}

.icon-gomeet-pin-camera:before {
    content: "\e918";
}

.icon-gomeet-polling:before {
    content: "\e919";
}

.icon-gomeet-raise-hand:before {
    content: "\e91a";
}

.icon-gomeet-reaction:before {
    content: "\e91b";
}

.icon-gomeet-record:before {
    content: "\e91c";
}

.icon-gomeet-record-pause:before {
    content: "\e91d";
}

.icon-gomeet-record-rec:before {
    content: "\e91e";
}

.icon-gomeet-record-resume:before {
    content: "\e91f";
}

.icon-gomeet-record2:before {
    content: "\e920";
}

.icon-gomeet-recording:before {
    content: "\e921";
}

.icon-gomeet-ringing:before {
    content: "\e922";
}

.icon-gomeet-security:before {
    content: "\e923";
}

.icon-gomeet-setting:before {
    content: "\e924";
}

.icon-gomeet-sharescreen:before {
    content: "\e925";
}

.icon-gomeet-shield:before {
    content: "\e926";
}

.icon-gomeet-speaker:before {
    content: "\e927";
}

.icon-gomeet-stop:before {
    content: "\e928";
}

.icon-gomeet-stop-share:before {
    content: "\e929";
}

.icon-gomeet-talking:before {
    content: "\e92a";
}

.icon-gomeet-time:before {
    content: "\e92b";
}

.icon-gomeet-timer:before {
    content: "\e92c";
}

.icon-gomeet-unlock:before {
    content: "\e92d";
}

.icon-gomeet-unmute:before {
    content: "\e92e";
}

.icon-gomeet-unmute-filled:before {
    content: "\e92f";
}

.icon-gomeet-video-start:before {
    content: "\e930";
}

.icon-gomeet-video-stop:before {
    content: "\e931";
}
.icon-gomeet-state-mic-filled:before {
    content: "\e932";
}

.icon-gomeet-state-mic:before {
    content: "\e933";
}

.icon-gomeet-state-micoff:before {
    content: "\e934";
}

.icon-gomeet-state-micban:before {
    content: "\e935";
}

.icon-gomeet-state-video:before {
    content: "\e936";
}

.icon-gomeet-state-videooff:before {
    content: "\e937";
}

.icon-gomeet-state-videoban:before {
    content: "\e938";
}

.icon-gomeet-add:before {
    content: "\e939";
}
.icon-gomeet-layout:before {
    content: "\e93a";
}

.icon-gomeet-layout2:before {
    content: "\e93b";
}

.icon-gomeet-layout3:before {
    content: "\e93c";
}

.icon-gomeet-fullscreen:before {
    content: "\e93d";
}

.icon-gomeet-filter:before {
    content: "\e93e";
}

.icon-gomeet-sort:before {
    content: "\e93f";
}

.icon-gomeet-search:before {
    content: "\e940";
}

.icon-gomeet-more-options:before {
    content: "\e941";
}