// mixins for clearfix
// ------------------------
.clearfix() {
  // https://github.com/oneui-design/oneui-design/issues/21301#issuecomment-583955229
  &::before {
    display: table;
    content: '';
  }
  &::after {
    // https://github.com/oneui-design/oneui-design/issues/21864
    display: table;
    clear: both;
    content: '';
  }
}
