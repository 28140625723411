@import '../../style/themes/index';
@import '../../style/mixins/index';

@slider-prefix-cls: ~'@{oneui-prefix}-slider';

.@{slider-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &-mark {
    .@{slider-prefix-cls}-rtl & {
      right: 0;
      left: auto;
    }
  }

  &-dot {
    .@{slider-prefix-cls}-rtl & {
      margin-right: -4px;
      margin-left: 0;
    }

    &:first-child {
      .@{slider-prefix-cls}-rtl & {
        margin-right: -4px;
        margin-left: 0;
      }
    }

    &:last-child {
      .@{slider-prefix-cls}-rtl & {
        margin-right: -4px;
        margin-left: 0;
      }
    }
  }
}

.vertical() {
  &-vertical {
    .@{slider-prefix-cls}-handle {
      .@{slider-prefix-cls}-rtl& {
        margin-right: -5px;
        margin-left: 0;
      }
    }

    .@{slider-prefix-cls}-mark {
      .@{slider-prefix-cls}-rtl& {
        right: 12px;
        left: auto;
      }
    }

    .@{slider-prefix-cls}-mark-text {
      .@{slider-prefix-cls}-rtl& {
        right: 4px;
        left: auto;
      }
    }

    .@{slider-prefix-cls}-dot {
      .@{slider-prefix-cls}-rtl& {
        right: 2px;
        left: auto;
      }
    }
  }
}
