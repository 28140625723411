.@{avatar-prefix-cls}-group {
  &-rtl {
    .@{avatar-prefix-cls}:not(:first-child) {
      margin-right: @avatar-group-overlapping;
      margin-left: 0;
    }
  }

  &-popover.@{oneui-prefix}-popover-rtl {
    .@{oneui-prefix}-avatar + .@{oneui-prefix}-avatar {
      margin-right: @avatar-group-space;
      margin-left: 0;
    }
  }
}
