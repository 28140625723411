.font-weight-bold {
  font-weight: 600 !important;
}

.font-size-0 {
  font-size: 6px !important;
}

.font-size-1 {
  font-size: @font-size-1  !important;
}

.font-size-2 {
  font-size: @font-size-2  !important;
}

.font-size-3 {
  font-size: @font-size-3  !important;
}

.font-size-4 {
  font-size: @font-size-4  !important;
}

.font-size-5 {
  font-size: @font-size-5  !important;
}

.font-size-6 {
  font-size: @font-size-6  !important;
}

.font-size-7 {
  font-size: @font-size-7  !important;
}

.font-size-8 {
  font-size: @font-size-8  !important;
}

.font-size-9 {
  font-size: @font-size-9  !important;
}

.font-size-10 {
  font-size: @font-size-10  !important;
}

.font-size-11 {
  font-size: @font-size-11  !important;
}

@media (min-width: @screen-xs-min) {
    .font-size-xs-0 {
      font-size: 0 !important;
    }

    .font-size-xs-1 {
      font-size: @font-size-1  !important;
    }

    .font-size-xs-2 {
      font-size: @font-size-2  !important;
    }

    .font-size-xs-3 {
      font-size: @font-size-3  !important;
    }

    .font-size-xs-4 {
      font-size: @font-size-4  !important;
    }

    .font-size-xs-5 {
      font-size: @font-size-5  !important;
    }

    .font-size-xs-6 {
      font-size: @font-size-6  !important;
    }

    .font-size-xs-7 {
      font-size: @font-size-7  !important;
    }

    .font-size-xs-8 {
      font-size: @font-size-8  !important;
    }

    .font-size-xs-9 {
      font-size: @font-size-9  !important;
    }

    .font-size-xs-10 {
      font-size: @font-size-10  !important;
    }

    .font-size-xs-11 {
      font-size: @font-size-11  !important;
    }
  }

@media (min-width: @screen-sm-min) {
  .font-size-sm-0 {
    font-size: 0 !important;
  }

  .font-size-sm-1 {
    font-size: @font-size-1  !important;
  }

  .font-size-sm-2 {
    font-size: @font-size-2  !important;
  }

  .font-size-sm-3 {
    font-size: @font-size-3  !important;
  }

  .font-size-sm-4 {
    font-size: @font-size-4  !important;
  }

  .font-size-sm-5 {
    font-size: @font-size-5  !important;
  }

  .font-size-sm-6 {
    font-size: @font-size-6  !important;
  }

  .font-size-sm-7 {
    font-size: @font-size-7  !important;
  }

  .font-size-sm-8 {
    font-size: @font-size-8  !important;
  }

  .font-size-sm-9 {
    font-size: @font-size-9  !important;
  }

  .font-size-sm-10 {
    font-size: @font-size-10  !important;
  }

  .font-size-sm-11 {
    font-size: @font-size-11  !important;
  }
}

@media (min-width: @screen-md-min) {
  .font-size-md-0 {
    font-size: 0 !important;
  }

  .font-size-md-1 {
    font-size: @font-size-1  !important;
  }

  .font-size-md-2 {
    font-size: @font-size-2  !important;
  }

  .font-size-md-3 {
    font-size: @font-size-3  !important;
  }

  .font-size-md-4 {
    font-size: @font-size-4  !important;
  }

  .font-size-md-5 {
    font-size: @font-size-5  !important;
  }

  .font-size-md-6 {
    font-size: @font-size-6  !important;
  }

  .font-size-md-7 {
    font-size: @font-size-7  !important;
  }

  .font-size-md-8 {
    font-size: @font-size-8  !important;
  }

  .font-size-md-9 {
    font-size: @font-size-9  !important;
  }

  .font-size-md-10 {
    font-size: @font-size-10  !important;
  }

  .font-size-md-11 {
    font-size: @font-size-11  !important;
  }
}

@media (min-width: @screen-lg-min) {
  .font-size-lg-0 {
    font-size: 0 !important;
  }

  .font-size-lg-1 {
    font-size: @font-size-1  !important;
  }

  .font-size-lg-2 {
    font-size: @font-size-2  !important;
  }

  .font-size-lg-3 {
    font-size: @font-size-3  !important;
  }

  .font-size-lg-4 {
    font-size: @font-size-4  !important;
  }

  .font-size-lg-5 {
    font-size: @font-size-5  !important;
  }

  .font-size-lg-6 {
    font-size: @font-size-6  !important;
  }

  .font-size-lg-7 {
    font-size: @font-size-7  !important;
  }

  .font-size-lg-8 {
    font-size: @font-size-8  !important;
  }

  .font-size-lg-9 {
    font-size: @font-size-9  !important;
  }

  .font-size-lg-10 {
    font-size: @font-size-10  !important;
  }

  .font-size-lg-11 {
    font-size: @font-size-11  !important;
  }
}

@media (min-width: @screen-xl-min) {
  .font-size-xl-0 {
    font-size: 0 !important;
  }

  .font-size-xl-1 {
    font-size: @font-size-1  !important;
  }

  .font-size-xl-2 {
    font-size: @font-size-2  !important;
  }

  .font-size-xl-3 {
    font-size: @font-size-3  !important;
  }

  .font-size-xl-4 {
    font-size: @font-size-4  !important;
  }

  .font-size-xl-5 {
    font-size: @font-size-5  !important;
  }

  .font-size-xl-6 {
    font-size: @font-size-6  !important;
  }

  .font-size-xl-7 {
    font-size: @font-size-7  !important;
  }

  .font-size-xl-8 {
    font-size: @font-size-8  !important;
  }

  .font-size-xl-9 {
    font-size: @font-size-9  !important;
  }

  .font-size-xl-10 {
    font-size: @font-size-10  !important;
  }

  .font-size-xl-11 {
    font-size: @font-size-11  !important;
  }
}

@media (min-width: @screen-xxl-min) {
  .font-size-xxl-0 {
    font-size: 0 !important;
  }

  .font-size-xxl-1 {
    font-size: @font-size-1  !important;
  }

  .font-size-xxl-2 {
    font-size: @font-size-2  !important;
  }

  .font-size-xxl-3 {
    font-size: @font-size-3  !important;
  }

  .font-size-xxl-4 {
    font-size: @font-size-4  !important;
  }

  .font-size-xxl-5 {
    font-size: @font-size-5  !important;
  }

  .font-size-xxl-6 {
    font-size: @font-size-6  !important;
  }

  .font-size-xxl-7 {
    font-size: @font-size-7  !important;
  }

  .font-size-xxl-8 {
    font-size: @font-size-8  !important;
  }

  .font-size-xxl-9 {
    font-size: @font-size-9  !important;
  }

  .font-size-xxl-10 {
    font-size: @font-size-10  !important;
  }

  .font-size-xxl-11 {
    font-size: @font-size-11  !important;
  }
}